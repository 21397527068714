import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, DatePicker } from 'antd';
import {
  setRuleRequired,
  setRuleEmail,
  setUpperCase,
  setLowerCase,
  setRuleMax,
  setRuleMin,
  setCapitalLetter,
  setInvalidFormat,
  disabledFutureDays,
  completePhoneNumber,
  setFormatMarkets,
  removeAccent
} from 'utils/validations';
import { dateFormat, validPDFFiles, headerBase64PDF } from 'utils/constants';
import { configValidBorn, loadingSelect, layoutInputs } from 'helpers/helperForms';
import { listAddressType, listDocumentsByGroup } from 'services/catalogs';
import UploadInput from 'components/common/UploadInput';
import AddressInputs from 'components/common//AddressInputs';
import MyButton from 'components/common/MyButton';
import MyModal from 'components/common/MyModal';
import STitle from 'components/common/STitle';
import SIDType from 'components/common/SIDType';
import SAddressType from 'components/common/SAddressType';
import PreviewPDF from 'components/common/PreviewPDF';
import TelephonesContact from 'components/common/TelephonesContact';
import FooterWizardActions from 'components/common/FooterWizardActions';
import PropTypes from 'prop-types';
import { mainHome } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import SDocumentAddressTypeID from 'components/common/SDocumentAddressTypeID';
import LanguagesSelect from '../../catalogs/LanguagesSelect';
import TimeZonesSelect from '../../catalogs/TimeZonesSelect';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setWizardForm } from 'redux/actions/wizardFormActions';
import { emptyUUID } from 'utils';
import UserProofs from 'components/UserProofs';
import { sendNotification } from 'helpers/helperNotifications';
import { proofGetRequest } from 'redux/actions/usersActions';
import { userStatus } from 'models/enums';

function Step2Component(props) {
  const {
    dataFile,
    enableInputs,
    externalRegister,
    form,
    formToState,
    next,
    prev,
    readOnly,
    type,
    userId,
    willBeUpdated,
    disableUpdateOnValidation,
    saving,
    blockBtn,
    locallyProofs,
    setLocallyProofs,
  } = props;
  let history = useHistory();
  let { previousForm, canUpdateBuyers, userIsLock, isRegister } = props;
  const { getFieldDecorator, getFieldsValue, validateFields } = form;
  const { push } = history;
  const [personalsID, setPersonalsID] = useState({ isLoading: false });
  const [addressID, setAddressID] = useState({ isLoading: false });
  const [addressType, setAddressType] = useState({ isLoading: false });
  const [documentData, setDocumentData] = useState();
  const [showModalDocument, setShowModalDocument] = useState(false);
  const [showRequiredDocument, setRequiredDocument] = useState(false);
  const isLoadingAddress = addressType.isLoading ? loadingSelect : '';
  // const isLoadingPersonalsID = personalsID.isLoading ? loadingSelect : '';
  const isLoadingAddressID = addressID.isLoading ? loadingSelect : '';
  const { t } = useTranslation();
  previousForm = typeof previousForm !== 'undefined' ? previousForm : false;
  previousForm =
    previousForm && previousForm.contactInfo ? { ...previousForm.contactInfo } : previousForm;
  canUpdateBuyers = typeof canUpdateBuyers !== 'undefined' ? canUpdateBuyers : false;
  userIsLock = typeof userIsLock !== 'undefined' ? userIsLock : false;
  userIsLock = externalRegister && !willBeUpdated ? false : userIsLock;

  const proofAddress = useSelector((state) => state.users.proofAddress);
  const {userData} = useSelector(state => state.wizardForm);

  useEffect(() => {
    let mounted = true;

    return () => {
      if(!readOnly && mounted && props.onDismount && !isRegister) {
        mounted = false;
        props.onDismount( formatDataToSave(props.form.getFieldsValue(), false), 1, 'moralBuyer');
      };
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const getAddress = async () => {
      try {
        setAddressType({
          isLoading: true
        });

        const response = await listAddressType(externalRegister ? i18n.language : false);
        if (typeof response !== 'undefined' && response.status === 200) {
          if (mounted) {
            setAddressType(() => {
              return {
                data: response.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setAddressType({
          isLoading: false
        });
      }
    };

    const getDocumentsGroups = async () => {
      try {
        setPersonalsID({
          isLoading: true
        });
        setAddressID({
          isLoading: true
        });

        const responseAddressID = await listDocumentsByGroup(1, externalRegister ? i18n.language : false);
        if (typeof responseAddressID !== 'undefined' && responseAddressID.status === 200) {
          if (mounted) {
            setAddressID(() => {
              return {
                data: responseAddressID.data.data,
                isLoading: false
              };
            });
          }
        }

        const responsePersonalsID = await listDocumentsByGroup(2, externalRegister ? i18n.language : false);
        if (typeof responsePersonalsID !== 'undefined' && responsePersonalsID.status === 200) {
          if (mounted) {
            setPersonalsID(() => {
              return {
                data: responsePersonalsID.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setAddressID({
          isLoading: false
        });
        setPersonalsID({
          isLoading: false
        });
      }
    };
    getAddress();
    getDocumentsGroups();
    return () => {
      mounted = false;
    };
  }, []);

  const formatDataToSave = (values, isMounted = true) => {
    let oldValues = { ...values };
    let newValues = {};
    let phoneNumbers = {
      phoneNumber: completePhoneNumber(values.prefixPhoneNumber, values.phoneNumber),
      onlyPhoneNumber: values.phoneNumber,
      prefixPhoneNumber: values.prefixPhoneNumber,
      onlyCellPhoneNumber: values.cellPhoneNumber,
      cellPhoneNumber: completePhoneNumber(values.prefixCellPhoneNumber, values.cellPhoneNumber),
      prefixCellPhoneNumber: values.prefixCellPhoneNumber,
      onlyFaxNumber: values.faxNumber,
      faxNumber: completePhoneNumber(values.prefixFaxNumber, values.faxNumber),
      prefixFaxNumber: values.prefixFaxNumber
    };
    let objAddress = {
      address: {
        roadName: values.roadName,
        outNumber: values.outNumber,
        inNumber: values.inNumber,
        neighborhood: values.neighborhood,
        town: values.town,
        city: values.city,
        stateId: values.stateId,
        countryId: values.countryId,
        zipCode: values.zipCode
      }
    };

    let withAddress = {
      ...oldValues,
      ...objAddress
    };
    newValues = {
      ...phoneNumbers,
      ...withAddress
    };

    if(!newValues.documentTypeId && userId) {
      newValues.documentTypeId = proofAddress?.data?.length > 0 ? proofAddress.data[0].documentTypeId : null;
    }

    const dataToSave = {
      contactInfo: { ...newValues}
    };

    return dataToSave;
  };

  const saveDataToStore = (values, isLast = true) => {
    const dataToSave = formatDataToSave(values);
    formToState(dataToSave, 1, isLast, 'Buyers/BuyerCompany', false, userIsLock, true);
  };

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleFooterActionButtons = actionId => {
    switch (actionId) {
      case 'submit':
        handleSubmitFinal();
        break;
      case 'update':
        enableInputs();
        break;
      case 'next':
        handleSubmit();
        break;
      case 'prev':
        handlePrev();
      break;
      case 'cancel':
        push(setToPageCancel());
        break;

      default:
        break;
    }
  };

  const disabledInputsOnForm = () => {
    let disabled = false;
    if (readOnly) disabled = true;
    if (userIsLock) disabled = true;
    return disabled;
  };

  const applyRequiredInputs = () => {
    let required = true;
    if (readOnly) required = false;
    if (userIsLock) required = false;
    return required;
  };

  const handleCancelTypeValidate = () => {
    history.push(mainHome);
  };

  const handleSubmitFinal = () => {
    validateFields(async (err, values) => {
      if((userId && !proofAddress?.data?.length) || (!locallyProofs?.length && !userId)) {
        sendNotification('error', t('MSG_170'));
      }else if (!err) {
        saveDataToStore(values, true);
        if (blockBtn) blockBtn();
      }
    });
  };

  const handleSubmit = () => {
    validateFields(async (err, values) => {
      if((userId && !proofAddress?.data?.length) || (!locallyProofs?.length && !userId)) {
        sendNotification('error', t('MSG_170'));
      }else if (!err) {
        saveDataToStore(values, false);
        if (blockBtn) blockBtn();
        next();
      }
    });
  };

  const handlePrev = () => {
    validateFields(async (err, values) => {
        saveDataToStore(values, false);
        if (blockBtn) blockBtn();
        prev();
    });
  };

  // eslint-disable-next-line no-console
  return (
    <Form noValidate layout="vertical">
      <STitle className="wizard-title" level={4} titleText={t('usersform.attorney')} />

      <Row gutter={24} className="row-forms">
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.name')}>
            {getFieldDecorator('givenName', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Nombre(s)')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.givenName ? previousForm.givenName : ''
            })(
              <Input
                disabled={disabledInputsOnForm()}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.firstlastname')}>
            {getFieldDecorator('surname', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Primer apellido')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.surname ? previousForm.surname : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.secondlastname')}>
            {getFieldDecorator('lastname', {
              rules: [setRuleMin(2), setRuleMax(50)],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.lastname ? previousForm.lastname : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.bussinesemail')}>
            {getFieldDecorator('email', {
              rules: [
                setRuleEmail('Correo electrónico empresarial'),
                setRuleRequired('Correo electrónico empresarial'),
                setRuleMax(80)
              ],
              normalize: value => removeAccent(value),
              initialValue: previousForm.email ? previousForm.email : ''
            })(
              <Input
                disabled={readOnly || externalRegister || userIsLock}
                placeholder={t('insuredforms.step1.describe')}
                autoComplete="none"
                type="email"
                maxLength={80}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.birth')}>
            {getFieldDecorator(
              'bornDate',
              configValidBorn(previousForm)
            )(
              <DatePicker
                disabled={readOnly || userIsLock}
                format={dateFormat}
                disabledDate={date => disabledFutureDays(date)}
                placeholder={t('system.select')}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.rfc')}>
            {getFieldDecorator('taxId', {
              rules: [
                setRuleRequired('RFC'),
                {
                  validator: (rule, value, callback) => {
                    setInvalidFormat('RFC', 'RFC', value, callback);
                  }
                }
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.taxId ? previousForm.taxId : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                autoComplete="off"
                type="string"
                minLength={11}
                maxLength={13}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.curp')}>
            {getFieldDecorator('curp', {
              rules: [
                setRuleRequired('CURP'),

                {
                  validator: (rule, value, callback) => {
                    setInvalidFormat('CURP', 'CURP', value, callback);
                  }
                }
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.curp ? previousForm.curp : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                autoComplete="off"
                minLength={18}
                maxLength={18}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          {/** Identification Type*/}
          <SIDType
            form={form}
            initialValue={previousForm.documentTypeId ? previousForm.documentTypeId : null}
            disabled={readOnly || userIsLock}
            personalsID={personalsID}
          />
        </Col>
        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.idfolio')}>
            {getFieldDecorator('documentNumber', {
              rules: applyRequiredInputs() && [
                setRuleRequired('Número de folio de identificación'),
                setRuleMin(2),
                setRuleMax(20)
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.documentNumber ? previousForm.documentNumber : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <LanguagesSelect
            form={form}
            disabled={readOnly || userIsLock}
            initialValue={previousForm.languageId && previousForm.languageId != emptyUUID ? previousForm.languageId : null}
          />
        </Col>

        <Col {...layoutInputs}>
          <TimeZonesSelect
            form={form}
            disabled={readOnly || userIsLock}
            initialValue={previousForm.timeZoneId && previousForm.timeZoneId != emptyUUID ? previousForm.timeZoneId : null}
          />
        </Col>
        {/** start address */}
        <Col {...layoutInputs}>
          <SAddressType
            form={form}
            {...isLoadingAddress}
            addressType={addressType}
            disabled={readOnly || userIsLock}
            initialValue={previousForm.addressTypeId ? previousForm.addressTypeId : []}
          />
        </Col>
        <AddressInputs
          readOnly={readOnly || userIsLock}
          previousForm={previousForm}
          form={form}
          fieldsNotRequired={['state', 'inNumber']}
        />
        {/** end address */}
        <TelephonesContact
          form={form}
          readOnly={readOnly || userIsLock}
          previousForm={previousForm ? previousForm : undefined}
          fieldsRequired={['cellPhoneNumber', 'phoneNumber']}
        />
        <Col sm={12} md={12} lg={12} xl={12} className="text-left">
          <UserProofs
            form={form}
            readOnly={readOnly || (userIsLock && userData?.companyInfo?.statusId == userStatus.INACTIVE)}
            userId={userId}
            locallyProofs={locallyProofs}
            setLocallyProofs={setLocallyProofs}
            addressID={addressID}
            proofAddress={proofAddress}
            isMoral={true}
          />
        </Col>
      </Row>

      {type === 'validate' ? (
        <FooterWizardActions handleAction={handleCancelTypeValidate} onCancel />
      ) : (
        <FooterWizardActions
          jest-id="footer"
          handleAction={handleFooterActionButtons}
          onCancel
          onSubmit={{ show: !readOnly && !isRegister, loading: saving, disabled: saving }}
          onNext={{ show: !readOnly && isRegister && !externalRegister, loading: saving, disabled: saving }}
          onPrev={{ show: !readOnly && (isRegister || externalRegister), loading: saving, disabled: saving }}
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
        />
      )}
    </Form>
  );
}

const Step2 = Form.create({ name: 'add-moral-buyer-user' })(Step2Component);

Step2.propTypes = {
  readOnly: PropTypes.bool,
  type: PropTypes.string
};
Step2.defaultProps = {
  readOnly: false,
  type: 'register'
};

export default Step2;
