import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Col, Row, Radio, Checkbox } from 'antd';
import { setRuleRequired, setRuleMax, setRuleMin, setCapitalLetter, setFormatMarkets } from 'utils/validations';
import SInput from 'components/common/SInput';
import STitle from 'components/common/STitle';
import FooterWizardActions from 'components/common/FooterWizardActions';
import { mainHome } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegimeSelect  from 'components/common/catalogs/RegimeSelect/RegimeSelect';
import MarketsCatalog from 'components/common/catalogs/MarketsCatalog';
import { useSelector } from 'react-redux';
import { validateAddressProof } from 'utils/helperActions';
import { userStatus } from 'models/enums';

function Step3Component(props) {
  const {
    currentProfile,
    enableInputs,
    externalRegister,
    form,
    formToState,
    isAdmin,
    prev,
    previousForm,
    readOnly,
    type,
    willBeUpdated,
    userId,
    saving,
    disableUpdateOnValidation,
    blockBtn,
    isLock
  } = props;

  const history = useHistory();
  const { getFieldDecorator, validateFields, getFieldsValue } = form;
  let { userIsLock, isRegister, canUpdateBuyers } = props;
  const { t } = useTranslation();
  const markets = useSelector(state => state.catalogs.markets);
  const proofAddress = useSelector((state) => state.users.proofAddress);
  const {userData} = useSelector(state => state.wizardForm);

  const [isPolitical, setIsPolitical] = useState(false);
  const [familiarIsPolitical, setFamiliarIsPolitical] = useState(false);
  const [regimeId, setRegimeId] = useState(null);
  const selectMarkets = isAdmin ? false : true;
  let previous = typeof previousForm !== 'undefined' ? previousForm : false;
  previous = typeof previous.workInfo !== 'undefined' ? { ...previous.workInfo } : previous;
  const previousQuestions =
    previous !== 'undefined' && previous.questions ? previous.questions : false;
  userIsLock = typeof userIsLock !== 'undefined' ? userIsLock : false;
  canUpdateBuyers = typeof canUpdateBuyers !== 'undefined' ? canUpdateBuyers : false;
  userIsLock = externalRegister ? false : userIsLock;

  useEffect(() => {
    const setInitialDependencyQuestions = () => {
      if (previousQuestions) {
        let existQuestion2 = previousQuestions.filter(
          q => q.questionId === 2 && q.answer.toLowerCase() === 'si'
        );
        if (existQuestion2.length > 0) {
          setIsPolitical({ questionId: 'question-2', answer: 'si' });
        }
        let existQuestion5 = previousQuestions.filter(
          q => q.questionId === 5 && q.answer.toLowerCase() === 'si'
        );
        if (existQuestion5.length > 0) {
          setFamiliarIsPolitical({ questionId: 'question-5', answer: 'si' });
        }
      }
    };
    setInitialDependencyQuestions();
  }, [previousQuestions]);

  useEffect(() => {
    return () => {
      if(!readOnly && props.onDismount) props.onDismount( formatDataToSave(props.form.getFieldsValue()), 2, 'phisicalBuyer' );
    }
  }, [])
  

  const formatDataToSave = values => {
    let oldValues = { ...values };
    let newValues = {};
    let setMarkets = {};
    let filterQuestions = [];
    let restData = {};
    Object.keys(values).map(i => {
      if (i.startsWith('question')) {
        let idQuestion = i.split('-');
        filterQuestions = [
          ...filterQuestions,
          { questionId: parseInt(idQuestion[1]), answer: values[i] }
        ];
      } else {
        restData = { ...restData, [i]: values[i] };
      }
      return null;
    });
    if (typeof values.insuranceCarriers !== 'undefined') {
      let marketsSelected = markets?.filter(m => {
        if (values.insuranceCarriers?.includes(m.id) && m.id != '*****') {
          return m;
        }
        return false;
      });
      setMarkets = marketsSelected.length > 1 ? marketsSelected : [...marketsSelected];
      if (setMarkets) {
        setMarkets = setMarkets.map(m => {
          return {
            id: m.id,
            name: m.name
          };
        });
      }
      newValues = {
        ...restData,
        insuranceCarriers: setMarkets
      };
    } else {
      newValues = {
        ...oldValues
      };
    }

    let auxQuestion1 = [
      {
        questionId: 3,
        answer: ''
      },
      { questionId: 4, answer: '' }
    ];

    let auxQuestion2 = [
      {
        questionId: 6,
        answer: ''
      }
    ];

    let finalQuestions = [];
    let existFirst = false;
    let existSecond = false;

    filterQuestions.map(q => {
      if (q.questionId === 2 && q.answer === 'si') {
        existFirst = true;
      }
      if (q.questionId === 5 && q.answer === 'si') {
        existSecond = true;
      }
      return null;
    });

    if (!existFirst) {
      finalQuestions = [...filterQuestions, ...auxQuestion1];
    } else {
      finalQuestions = filterQuestions;
    }
    if (!existSecond) {
      finalQuestions = [...finalQuestions, ...auxQuestion2];
    } else {
      finalQuestions = [...finalQuestions];
    }

    if (existFirst && existSecond) {
      finalQuestions = [...finalQuestions];
    }

    newValues = {
      ...newValues,
      questions: finalQuestions
    };

    const dataToSave = {
      workInfo: { ...newValues },
      roleId: currentProfile
    };

    return dataToSave;
  };

  const saveDataToStore = (values, saveAsFinalStep) => {
    const dataToSave = formatDataToSave(values);
    let endPointRegister = isRegister ? 'Buyers/Buyer' : 'Buyers';
    formToState(dataToSave, 2, saveAsFinalStep, endPointRegister, undefined, userIsLock);
  };

  const handlePreviousButtonOnClick = () => {
    const values = getFieldsValue();
    saveDataToStore(values, false);
    prev();
  };

  const handleFinalSubmit = () => {
    if(validateAddressProof(proofAddress, (isRegister || externalRegister || userData?.personalInfo?.statusId == userStatus.INACTIVE))){
      validateFields(async (err, values) => {
        if (!err) {
          saveDataToStore(values, true);
          if (blockBtn) blockBtn();
        }
      });
    }
  };

  const createMarketsCheckbox = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      return markets.map(m => {
        return {
          label: m.businessName,
          value: m.id,
          checked: true,
          defaultChecked: true
        };
      });
    }
  };

  const createDefaultValues = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      let defaultValues = [];
      if (previous && previous.insuranceCarriers) {
        defaultValues = previous.insuranceCarriers.map(m => m.id);
        return defaultValues;
      } else {
        defaultValues = markets.map(m => m.id);
      }
      return defaultValues;
    }
  };

  const getValueQuestion = questionId => {
    let data = '';
    data = previous.questions.filter(q => parseInt(q.questionId) === questionId);
    return data[0] && data[0].answer.toLowerCase();
  };

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleFooterActionButtons = actionId => {
    switch (actionId) {
      case 'cancel':
        history.push(setToPageCancel());
        break;
      case 'update':
        enableInputs();
        break;
      case 'prev':
        handlePreviousButtonOnClick();
        break;
      case 'submit':
        handleFinalSubmit();
        break;

      default:
        break;
    }
  };

  const handleCancelTypeValidate = () => {
    history.push(mainHome);
  };

  const handleRegime = (e) =>{
    setRegimeId(e);
  }

  return (
    <Form noValidate layout="vertical">
      <STitle className="wizard-title" level={4} titleText={t('usersform.personalinfo')} />
      <Row gutter={24}>

        <Col sm={12} md={12} lg={6} xl={6}>
          <RegimeSelect 
            form={form}
            disabled={readOnly || userIsLock}
            currentProfile={currentProfile}
            onChange={handleRegime}
            initialValue={previous.regimeId ? previous.regimeId : null}
          />
        </Col>

        { regimeId === 1 && (
          <Col sm={12} md={12} lg={6} xl={6}>
            <Form.Item label={t('usersform.otherregime')}>
              {getFieldDecorator('businessType', {
                rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Giro o Regimen')],
                normalize: value => setFormatMarkets(value),
                initialValue: previous && previous.businessType ? previous.businessType : ''
              })(
                <Input
                  disabled={readOnly || userIsLock}
                  placeholder=""
                  autoComplete="off"
                  type="string"
                  minLength={2}
                  maxLength={50}
                />
              )}
            </Form.Item>
          </Col>
        ) }

        <Col sm={12} md={12} lg={6} xl={6}>
          <Form.Item label={t('usersform.profession')}>
            {getFieldDecorator('grade', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Profesión')],
              normalize: value => setFormatMarkets(value),
              initialValue: previous && previous.grade ? previous.grade : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col sm={12} md={12} lg={6} xl={6}>
          <Form.Item label={t('usersform.occupation')}>
            {getFieldDecorator('occupation', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Ocupación')],
              normalize: value => setFormatMarkets(value),
              initialValue: previous && previous.occupation ? previous.occupation : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={6} xl={6}>
          <Form.Item label={t('usersform.position')}>
            {getFieldDecorator('job', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Puesto')],
              normalize: value => setFormatMarkets(value),
              initialValue: previous && previous.job ? previous.job : ''
            })(
              <Input
                disabled={readOnly || userIsLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={24} lg={24}>
          <Row gutter={24} type="flex" justify="start">
            <Col sm={12} md={12} lg={5} xl={5}>
              <Form.Item label={t('usersform.ownbusiness')}>
                {getFieldDecorator('question-1', {
                  valuePropName: 'value',
                  // trigger: ['onChange'],
                  rules: [{ required: true, message: 'Campo obligatorio' }],
                  initialValue: previous && previous.questions ? getValueQuestion(1) : ''
                })(
                  <Radio.Group
                    disabled={readOnly || userIsLock}
                    style={{
                      width: '100%',
                      padding: '5px',
                      textAlign: 'left'
                    }}
                  >
                    <Radio value="si">sí</Radio>
                    <Radio value="no">no</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={7} xl={7}>
              <Form.Item label={t('usersform.politically')}>
                {getFieldDecorator('question-2', {
                  valuePropName: 'value',
                  //trigger: ['onChange'],
                  rules: [{ required: true, message: 'Campo obligatorio' }],
                  initialValue: previous && previous.questions ? getValueQuestion(2) : ''
                })(
                  <Radio.Group
                    jest-id="radio"
                    disabled={readOnly || userIsLock}
                    name="question2"
                    onChange={v => {
                      if (v.target.value === 'si') {
                        setIsPolitical({ questionId: v.target.name, answer: v.target.value });
                      } else {
                        setIsPolitical(false);
                      }
                    }}
                    style={{
                      width: '100%',
                      padding: '5px',
                      textAlign: 'left'
                    }}
                  >
                    <Radio value="si">sí</Radio>
                    <Radio value="no">no</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>

            {isPolitical && (
              <Col sm={24} md={24} lg={12} xl={12}>
                <Row type="flex" gutter={24} justify="center">
                  <Col sm={24} md={24} lg={12} xl={12}>
                    <Form.Item label={t('usersform.position')}>
                      {getFieldDecorator('question-3', {
                        rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Puesto')],
                        normalize: value => setFormatMarkets(value),
                        initialValue: previous && previous.questions ? getValueQuestion(3) : ''
                      })(
                        <Input
                          disabled={readOnly || userIsLock}
                          placeholder=""
                          autoComplete="off"
                          type="string"
                          minLength={2}
                          maxLength={50}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col sm={24} md={24} lg={12} xl={12}>
                    <Form.Item label={t('usersform.politicallyopt1')}>
                      {getFieldDecorator('question-4', {
                        rules: [setRuleMin(2), setRuleMax(4), setRuleRequired('Puesto')],
                        //normalize: value => setCapitalLetter(value),
                        initialValue: previous && previous.questions ? getValueQuestion(4) : ''
                      })(
                        <Input
                          disabled={readOnly || userIsLock}
                          placeholder=""
                          autoComplete="off"
                          type="string"
                          minLength={2}
                          maxLength={4}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            <Col sm={24} md={16} lg={12} xl={12}>
              <Form.Item label={t('usersform.politicallyfam')}>
                {getFieldDecorator('question-5', {
                  valuePropName: 'value',

                  //trigger: ['onChange'],
                  rules: [{ required: true, message: 'Campo obligatorio' }],
                  initialValue: previous && previous.questions ? getValueQuestion(5) : ''
                })(
                  <Radio.Group
                    disabled={readOnly || userIsLock}
                    name="question3"
                    onChange={v => {
                      if (v.target.value === 'si') {
                        setFamiliarIsPolitical({
                          questionId: v.target.name,
                          answer: v.target.value
                        });
                      } else {
                        setFamiliarIsPolitical(false);
                      }
                    }}
                    style={{
                      width: '100%',
                      padding: '5px',
                      textAlign: 'left'
                    }}
                  >
                    <Radio value="si">sí</Radio>
                    <Radio value="no">no</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>

            {familiarIsPolitical && (
              <Col sm={24} md={8} lg={6} xl={6}>
                <Form.Item label={t('usersform.politicallyfamopt1')}>
                  {getFieldDecorator('question-6', {
                    rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Puesto')],
                    normalize: value => setFormatMarkets(value),
                    initialValue: previous && previous.questions ? getValueQuestion(6) : ''
                  })(
                    <Input
                      disabled={readOnly || userIsLock}
                      placeholder=""
                      autoComplete="off"
                      type="string"
                      minLength={2}
                      maxLength={50}
                    />
                  )}
                </Form.Item>
              </Col>
            )}

            <Col sm={12} md={12} lg={10} xl={10}>
              <Form.Item label={t('usersform.thirdperson')}>
                {getFieldDecorator('question-7', {
                  valuePropName: 'value',

                  //trigger: ['onChange'],
                  rules: [{ required: true, message: 'Campo obligatorio' }],
                  initialValue: previous && previous.questions ? getValueQuestion(7) : ''
                })(
                  <Radio.Group
                    disabled={readOnly || userIsLock}
                    style={{
                      width: '100%',
                      padding: '5px',
                      textAlign: 'left'
                    }}
                  >
                    <Radio value="si">sí</Radio>
                    <Radio value="no">no</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {!externalRegister && type !== 'validate' && (
          <Col sm={24} md={24} lg={16} xl={16}>
            <MarketsCatalog
                    label={t('table.insurance')}
                    isRequired={true}
                    form={form}
                    inputKey="insuranceCarriers"
                    catalogType="checkbox"
                    withAllOption={true}
                    isModeTagsUsed={true}
                    mode="multiple"
                    cbxHeight={200}
                    disabled={readOnly || selectMarkets}
                    optionsByDefault={previous && previous.insuranceCarriers ? previous.insuranceCarriers : []}
                    />
          </Col>
        )}

        {!willBeUpdated && !externalRegister && (
          <Col sm={12} md={12} lg={8} xl={8}>
            <SInput
              label={t('usersform.comments')}
              form={form}
              disabled={readOnly || userIsLock}
              inputKey="comments"
              inputType="textarea"
              initialValue={previous && previous.comments ? previous.comments : ''}
              maxRequired={120}
              maxLength={120}
              minRequired={2}
              rows={3}
              normalize='formatMarkets'
            />
          </Col>
        )}
      </Row>
      {type === 'validate' ? (
        <FooterWizardActions jest-id="actions" onCancel handleAction={handleCancelTypeValidate} />
      ) : (
        <FooterWizardActions
          jest-id="footer"
          onCancel
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
          onPrev={{ show: !readOnly && (isRegister || externalRegister), disabled: saving}}
          onSubmit={{ show: !readOnly, loading: saving, disabled: saving }}
          handleAction={handleFooterActionButtons}
        />
      )}
    </Form>
  );
}

const Step3 = Form.create({ name: 'add-personal-buyer-user' })(Step3Component);

Step3Component.defaultProps = {
  blockBtn: undefined
};

Step3Component.propTypes = {
  blockBtn: PropTypes.func
};

export default Step3;
