import React from 'react';
import PropTypes from 'prop-types';

import SSelect from 'components/common/SSelect';
import { useTranslation } from 'react-i18next';

function SAddressType(props) {
  const { form, initialValue, addressType, ...other } = props;
  const { t } = useTranslation();

  return (
    <SSelect
      form={form}
      initialValue={initialValue}
      inputKey="addressTypeId"
      isLoading={addressType?.isLoading}
      label={t('usersform.addresstype')}
      optionKey="id"
      isRequired={true}
      options={addressType?.data}
      placeholder={t('system.select')}
      valueKey="id"
      {...other}
    />
  );
}

SAddressType.propTypes = {
  initialValue: PropTypes.any,
  advertStatus: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestAdvertStatus: PropTypes.func
};

SAddressType.defaultProps = {
  initialValue: undefined,
  advertStatus: { data: [], isLoading: false },
  requestAdvertStatus: null
};

export default SAddressType;
