import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'antd';
import { completePhoneNumber } from 'utils/validations';
import { loadingSelect } from 'helpers/helperForms';
import { listAddressType, listDocumentsByGroup } from 'services/catalogs';
import UploadInput from 'components/common/UploadInput';
import AddressInputs from 'components/common/AddressInputs';
import TelephonesContact from 'components/common/TelephonesContact';
import SButton from 'components/common/SButton';
import MyModal from 'components/common/MyModal';
import STitle from 'components/common/STitle';
import PreviewPDF from '../../PreviewPDF';
import FooterWizardActions from 'components/common/FooterWizardActions';
import { objectHasProperty } from 'utils';
import { mainHome, validPDFFiles, headerBase64PDF } from 'utils/constants';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SAddressType from 'components/common/SAddressType';
import SDocumentAddressTypeID from 'components/common/SDocumentAddressTypeID';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import SToggleModalControl from 'components/common/SToggleModalControl';
import UserProofs from 'components/UserProofs';
import { sendNotification } from 'helpers/helperNotifications';
import { proofGetRequest } from 'redux/actions/usersActions';
import { userStatus } from 'models/enums';

function Step2Component(props) {
  let history = useHistory();
  const {
    canUpdateBuyers,
    userId,
    dataFile,
    enableInputs,
    externalRegister,
    form,
    formToState,
    readOnly,
    type,
    willBeUpdated,
    prev,
    next,
    saving,
    blockBtn,
    disableUpdateOnValidation,
    isRegister,
    locallyProofs, 
    setLocallyProofs
  } = props;
  let { previousForm, userIsLock } = props;
  const { validateFields, getFieldsValue } = form;

  const [addressID, setAddressID] = useState({ isLoading: false });
  const [addressType, setAddressType] = useState({ isLoading: false });
  const isLoadingAddress = addressType.isLoading ? loadingSelect : '';
  const { t } = useTranslation();
  const proofAddress = useSelector((state) => state.users.proofAddress);
  const {userData} = useSelector(state => state.wizardForm);

  const isLoadingAddressID = addressID.isLoading ? loadingSelect : '';
  previousForm = typeof previousForm !== 'undefined' ? previousForm : false;
  previousForm = objectHasProperty(previousForm, 'contactInfo')
    ? { ...previousForm.contactInfo }
    : previousForm;
  userIsLock = typeof userIsLock !== 'undefined' ? userIsLock : false;
  userIsLock = externalRegister && !willBeUpdated ? false : userIsLock;
  useEffect(() => {
    let mounted = true;
    const getAddress = async () => {
      try {
        setAddressType({
          isLoading: true
        });

        const response = await listAddressType(externalRegister ? i18n.language : false);
        if (typeof response !== 'undefined' && response.status === 200) {
          if (mounted) {
            setAddressType(() => {
              return {
                data: response.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setAddressType({
          isLoading: false
        });
      }
    };
    const getDocumentsGroups = async () => {
      try {
        setAddressID({
          isLoading: true
        });

        const responseAddressID = await listDocumentsByGroup(1, externalRegister ? i18n.language : false);
        if (typeof responseAddressID !== 'undefined' && responseAddressID.status === 200) {
          if (mounted) {
            setAddressID(() => {
              return {
                data: responseAddressID.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setAddressID({
          isLoading: false
        });
      }
    };
    getAddress();
    getDocumentsGroups();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (userId && proofAddress?.data?.length > 0) {
      form.setFieldsValue({documentTypeId: proofAddress.data[0].documentTypeId});
    }
  }, [proofAddress])
  

  useEffect(() => {
    let mounted = true;
    return () => {
      if(!readOnly && mounted && props.onDismount && !isRegister) {
        mounted = false;
          props.onDismount( formatDataToSave(props.form.getFieldsValue(), false), 1, 'phisicalBuyer');
      };
    }
  }, [])

  const formatDataToSave = (values, isMounted = true) => {
    let oldValues = { ...values };
    let newValues = {};
    let phoneNumbers = {
      phoneNumber: completePhoneNumber(values.prefixPhoneNumber, values.phoneNumber),
      onlyPhoneNumber: values.phoneNumber,
      prefixPhoneNumber: values.prefixPhoneNumber,
      onlyCellPhoneNumber: values.cellPhoneNumber,
      cellPhoneNumber: completePhoneNumber(values.prefixCellPhoneNumber, values.cellPhoneNumber),
      prefixCellPhoneNumber: values.prefixCellPhoneNumber,
      onlyFaxNumber: values.faxNumber,
      faxNumber: completePhoneNumber(values.prefixFaxNumber, values.faxNumber),
      prefixFaxNumber: values.prefixFaxNumber
    };
    let objAddress = {
      address: {
        roadName: values.roadName,
        outNumber: values.outNumber,
        inNumber: values.inNumber,
        neighborhood: values.neighborhood,
        town: values.town,
        city: values.city,
        stateId: values.stateId,
        countryId: values.countryId,
        zipCode: values.zipCode
      }
    };
    let withAddress = {
      ...oldValues,
      ...objAddress
    };

    newValues = {
      ...phoneNumbers,
      ...withAddress
    };

    if(!newValues.documentTypeId && userId) {
      newValues.documentTypeId = proofAddress?.data?.length > 0 ? proofAddress.data[0].documentTypeId : null;
    }

    const dataToSave = {
      contactInfo: { ...newValues },
    };

    return dataToSave;
  };

  const saveDataToStore = (values, isLast = true) => {
    const dataToSave = formatDataToSave(values);
    formToState(dataToSave, 1, isLast, 'Buyers', undefined, userIsLock, false);
  };

  const handlePreviousButtonOnClick = () => {
    const values = getFieldsValue();
    saveDataToStore(values, false);
    prev();
  };

  const handleNextButtonOnClick = () => {
    validateFields(async (err, values) => {
      if((userId && !proofAddress?.data?.length) || (!locallyProofs?.length && !userId)) {
        sendNotification('error', t('MSG_170'));
      }else if (!err) {
        saveDataToStore(values, false);
        next();
      }
    });
  };

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleFinalSubmit = () => {
      validateFields(async (err, values) => {
      if(((userId && !proofAddress?.data?.length) || (!locallyProofs?.length && !userId)) && userData?.personalInfo?.statusId != userStatus.INACTIVE) {
        sendNotification('error', t('MSG_170'));
      }else if (!err) {
          saveDataToStore(values, true);
          if (blockBtn) blockBtn();
        }
      });
  };

  const handleFooterActionButtons = actionId => {
    switch (actionId) {
      case 'cancel':
        history.push(setToPageCancel());
        break;

      case 'update':
        enableInputs();
        break;
      case 'next':
        handleNextButtonOnClick();
      break;
      case 'prev':
        handlePreviousButtonOnClick();
      break;
      case 'submit':
        handleFinalSubmit();
        break;

      default:
        break;
    }
  };

  const handleCancelTypeValidate = () => {
    history.push(mainHome);
  };

  return (
    <Form noValidate layout="vertical">
      <STitle className="wizard-title" level={4} titleText={t('usersform.contactinformation')} />
      <Row gutter={24}>
        {/** start address */}
        <Col sm={12} md={11} lg={8} xl={8}>
          <SAddressType
            form={form}
            {...isLoadingAddress}
            addressType={addressType}
            disabled={readOnly || userIsLock}
            initialValue={previousForm.addressTypeId ? previousForm.addressTypeId : null}
          />
        </Col>

        <AddressInputs
          readOnly={readOnly || userIsLock}
          previousForm={previousForm}
          form={form}
          fieldsNotRequired={['state', 'inNumber']}
        />

        <TelephonesContact
          form={form}
          readOnly={readOnly || userIsLock}
          previousForm={previousForm ? previousForm : undefined}
          fieldsRequired={['cellPhoneNumber', 'phoneNumber']}
        />

        <Col sm={12} md={12} lg={12} xl={12} className="text-left">
          <UserProofs
            form={form}
            readOnly={readOnly || (userIsLock && userData?.personalInfo?.statusId == userStatus.INACTIVE)}
            userId={userId}
            locallyProofs={locallyProofs}
            setLocallyProofs={setLocallyProofs}
            addressID={addressID}
            proofAddress={proofAddress}
            isMoral={false}
          />
        </Col>
      </Row>

      {type === 'validate' ? (
        <FooterWizardActions jest-id="footer" onCancel handleAction={handleCancelTypeValidate} />
      ) : (
        <FooterWizardActions
          jest-id="footer"
          handleAction={handleFooterActionButtons}
          onCancel
          onSubmit={{ show: !readOnly && !isRegister && !externalRegister, loading: saving, disabled: saving }}
          onPrev={{ show: !readOnly && (isRegister || externalRegister), disabled: saving}}
          onNext={{ show: !readOnly && (isRegister || externalRegister), disabled: saving}}
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
        />
      )}
    </Form>
  );
}

const Step2 = Form.create({ name: 'add-personal-buyer-user' })(Step2Component);

Step2.propTypes = {
  readOnly: PropTypes.bool,
  type: PropTypes.string
};
Step2.defaultProps = {
  readOnly: false,
  type: 'register'
};

export default Step2;
