import React, { useState, useEffect } from 'react';
import SButton from 'components/common/SButton';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
/**
 * FooterWizardActions renders and controls an array of buttons for forms
 *  🧪 Ccmponent with 100% coverage
 */

function FooterWizardActions({
  onSubmit,
  onCancel,
  onPrev,
  onUpdate,
  onNext,
  onExtra,
  handleAction
}) {
  const [buttonsArray, setButtonsArray] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let newButtonsArray = [];
    // ! This should be an object
    if (onCancel) {
      newButtonsArray.push({
        actionId: 'cancel',
        type: 'default',
        label: t('system.cancel')
      });
    }

    if (onPrev.show) {
      newButtonsArray.push({
        actionId: 'prev',
        type: 'default',
        label: t("system.return"),
        action: onPrev.action
      });
    }

    if (onExtra.show) {
      newButtonsArray.push({
        actionId: 'extra',
        type: 'primary',
        label: onExtra.label,
        action: onExtra.action,
        disabled: onExtra.disabled,
        loading: onExtra.loading
      });
    }

    if (onNext.show) {
      newButtonsArray.push({
        actionId: 'next',
        // ?This sould be type primary
        type: 'primary',
        label: t("system.next"),
        action: onNext.action
      });
    }

    if (onUpdate.show) {
      newButtonsArray.push({
        actionId: 'update',
        type: 'primary',
        label: t("system.update"),
        action: onUpdate.action,
        disabled: onUpdate.disabled,
        loading: onSubmit.loading
      });
    }

    if (onSubmit.show) {
      newButtonsArray.push({
        actionId: 'submit',
        type: 'primary',
        label: t("system.acept"),
        action: onUpdate.action,
        disabled: onSubmit.disabled,
        loading: onSubmit.loading
      });
    }

    setButtonsArray(newButtonsArray);
  }, [onCancel, onNext, onPrev, onSubmit, onExtra, onUpdate]);

  const onButtonPressed = (e, actionId, action) => {
    e.preventDefault();
    handleAction(actionId, action);
  };

  return (
    <div className="steps-action float-right" style={{ marginTop: '1rem' }}>
      {buttonsArray.length > 0 &&
        buttonsArray.map(button => {
          return (
            <SButton
              data-testid={button.actionId}
              key={button.actionId}
              type={button.type}
              onClick={e => onButtonPressed(e, button.actionId, button.action)}
              label={button.label}
              disabled={button.disabled}
              loading={button.loading}
            />
          );
        })}
    </div>
  );
}

export default withRouter(FooterWizardActions);

export const actionIdEnum = {
  ON_CANCEL: 'onCancel',
  ON_PREV: 'onPrev'
};
FooterWizardActions.propTypes = {
  // ! This should be an object
  onCancel: PropTypes.any,
  onPrev: PropTypes.object,
  onUpdate: PropTypes.object,
  onNext: PropTypes.object,
  onSubmit: PropTypes.object,
  onExtra: PropTypes.object,
  handleAction: PropTypes.func
};

FooterWizardActions.defaultProps = {
  // ! This should be an object
  onCancel: undefined,
  onPrev: { show: false },
  onUpdate: { show: false },
  onNext: { show: false },
  onSubmit: { show: false },
  onExtra: { show: false },
  handleAction: () => { }
};
