import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';
// import { generateUUID } from '../../../utils';
// import { VTComponents, VTScroll } from 'virtualizedtableforantd';
import PropTypes from 'prop-types';
import SkeletonTable from 'components/common/SkeletonTable';
import SEmpty from 'components/common/SEmpty';
import { useTranslation } from 'react-i18next';
/* const ctx = { top: 0 }; */

function MyTable(props) {
  const {
    columns,
    dataSource,
    dataIndex,
    size,
    className,
    rowKey,
    customPageSize,
    withRowNumbers,
    isLoading,
    description,
    currentPage,
    totalPage,
    paginationProps,
    hasHeaderFilter,
    pageLoading,
    widthColumn,
    hideOnEmpty,
    ...other
  } = props;
  /*  Pagination will restart on index page 1 every time de dataSource changes  */
  const [page, setPage] = useState(1);
  const pageSize = useRef([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage);
    } else {
      setPage(1);
    }
  }, [dataSource, currentPage]);

  const setColumns = () => {
    if (columns && columns.length) {
      columns.map(col => {
        if (col && (!col.sorter || col.noFilter)) {
          col.title = t(col.title);
        } else if (col && col.titleaux) {
          col.title = t(col.titleaux);
        }
      });
    }

    const haveFixed = columns.filter(x => x.fixed != null);

    let columnsToSet = [];

    if (withRowNumbers) {
      columnsToSet = [
        {
          align: 'center',
          title: t('table.nofila'),
          key: 'index',
          dataIndex: 'index',
          fixed: other?.scroll && Object.keys(other?.scroll).length > 0 && haveFixed.length > 0 ? 'left' : false,
          render: (value, item, index) => {
            return <span>{(page - 1) * customPageSize + index + 1}</span>;
          },
        },
        ...columns
      ];
    } else {
      columnsToSet = columns;
    }

    if (widthColumn !== undefined && widthColumn !== null && widthColumn !== 0) {
      columnsToSet = columnsToSet.map(col => {
        return {
          ...col,
          width: widthColumn
        }
      }
      );
    }

    return columnsToSet;
  };
  const setPageSize = () => {
    if (typeof customPageSize !== 'undefined' && customPageSize !== 10) {
      pageSize.current = [`${customPageSize}`];
      pageSize.current = dataSource.length > 10 ? [...pageSize.current] : pageSize.current;
    } else {
      pageSize.current = ['10'];
    }

    pageSize.current = dataSource.length > 30 ? [...pageSize.current, '30'] : pageSize.current;
    pageSize.current = dataSource.length > 40 ? [...pageSize.current, '40'] : pageSize.current;
    return pageSize.current;
  };

  const setCurrentPage = current => {
    setPage(current);
  };

  const getTotalPage = () => {
    if (totalPage) {
      let sizePages = setPageSize();
      return { total: totalPage * sizePages[0] };
    } else {
      return {};
    }
  };

  getTotalPage();

  const setPagination = () => {
    if (typeof paginationProps !== 'undefined') {
      return { pagination: paginationProps };
    } else {
      return {
        pagination: {
          current: page,
          defaultPageSize: customPageSize,
          showSizeChanger: true,
          pageSizeOptions: setPageSize(),
          onChange: setCurrentPage,
          ...getTotalPage()
        }
      };
    }
  };

  return (
    <>
      {isLoading && <SkeletonTable />}
      {((!isLoading && dataSource.length > 0) || (!isLoading && hasHeaderFilter) || !hideOnEmpty) && (
        <Table
          locale={{
            emptyText: <SEmpty type="table" description={description} />
          }}
          className={`${className} `}
          size={size}
          data-testid="myTable"
          {...setPagination()}
          //scroll={{ y: 550 }}
          //components={VTComponents({ id: 1000 })}
          // columns={!withRowNumbers || columns.length <= 0 ? columns : formatedTableColumns}
          columns={setColumns()}
          rowKey={rowKey}
          dataIndex={dataIndex}
          dataSource={dataSource}
          loading={pageLoading}
          {...other}
        />
      )}
      {!isLoading && dataSource.length === 0 && !hasHeaderFilter && hideOnEmpty && (
        <SEmpty type="table" description={description} />
      )}
    </>
  );
}

MyTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  customPageSize: PropTypes.number,
  dataIndex: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.oneOf(['default', 'middle', 'small', 'default']),
  withRowNumbers: PropTypes.bool,
  isLoading: PropTypes.bool,
  description: PropTypes.string,
  hasHeaderFilter: PropTypes.bool,
  pageLoading: PropTypes.bool,
  hideOnEmpty: PropTypes.bool
};

MyTable.defaultProps = {
  className: 'data-table',
  columns: [],
  customPageSize: 10,
  dataIndex: null,
  dataSource: [],
  others: null,
  rowKey: 'id',
  size: 'small',
  withRowNumbers: true,
  isLoading: false,
  description: '',
  hasHeaderFilter: false,
  pageLoading: false,
  hideOnEmpty: true
};

export default MyTable;
