import React from 'react';
import { createBase64File } from 'utils/';
import PropTypes from 'prop-types';
import PDFViewer from 'pdf-viewer-reactjs';
import CustomNavigation from './Navigation';
import ZoomImage from '../ZoomImage';
import { MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-input-position';

function PreviewPDF(props) {
  const { base64, titleDocument, type, metadata: Metadata, helperCreateLinkSource } = props;

  const generateLinkSource = () => {
    return helperCreateLinkSource(base64, type);
  };

  return (
    <>
      <h3 className="modal-title">{titleDocument}</h3>
      <br />
      <br />
      <div>
        <div className="iframe-container">
          {type === 'data:application/pdf;base64,' ? (
            <PDFViewer
              document={{
                base64: base64
              }}
              scale={1.5}
              handleZoomIn
              css="customViewer"
              canvasCss="customCanvas"
              navigation={CustomNavigation}
            />
          ) : (
            <ZoomImage
              imageSrc={generateLinkSource()}
              imageAlt={titleDocument}
              mouseActivation={MOUSE_ACTIVATION.CLICK}
              touchActivation={TOUCH_ACTIVATION.TAP}
            />
          )}
        </div>
        {Metadata && <Metadata {...props} />}
        {/* {isValidateAttachmentPage && <Comments {...props} />} */}
      </div>
    </>
  );
}

PreviewPDF.propTypes = {
  /* Describe in detail */
  base64: PropTypes.any,
  /* Describe in detail */
  titleDocument: PropTypes.any,
  /* Describe in detail */
  comments: PropTypes.string,
  /* Describe in detail */
  type: PropTypes.any,
  /* Describe in detail */
  actions: PropTypes.any,
  /* Describe in detail */
  fileId: PropTypes.any,
  /* status of the document 
  1=sin asiganación status 2=aprobado 3=rechazado 
  */
  statusId: PropTypes.number,
  isValidate: PropTypes.bool,
  helperCreateLinkSource: PropTypes.func
};

PreviewPDF.defaultProps = {
  base64: null,
  titleDocument: null,
  comments: '',
  type: null,
  actions: false,
  fileId: null,
  statusId: null,
  isValidate: false,
  helperCreateLinkSource: createBase64File
};
export default PreviewPDF;
