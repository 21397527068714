import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Form, Input, Col, Row, DatePicker, Checkbox, Modal } from 'antd';
import {
  setRuleRequired,
  setRuleMin,
  setRuleMax,
  setCapitalLetter,
  disabledPastDays,
  disabledFutureDays,
  setInvalidFormat,
  setUpperCase,
  setRuleEmail,
  setLowerCase,
  setFormatMarkets,
  removeAccent
} from 'utils/validations';
import { mainHome, dateFormat } from 'utils/constants';
import {
  layoutInputs,
  configValidFrom,
  configValidBorn,
  configValidTo,
  loadingSelect
} from 'helpers/helperForms';
import { getInsuranceTypes, listDocumentsByGroup } from 'services/catalogs';
import InfoUsersOnReadOnly from 'components/common/InfoUsersOnReadOnly';
import MyLink from 'components/common/MyLink';
import SInput from 'components/common/SInput';
import SSwitch from 'components/common/SSwitch';
import SButton from 'components/common/SButton';
import STitle from 'components/common/STitle';
import FooterWizardActions from 'components/common/FooterWizardActions';
import PropTypes from 'prop-types';
import ModalWithTermsAndConditions from 'components/common/ModalWithTermsAndConditions';
import { useHistory } from 'react-router-dom';
import SIDType from 'components/common/SIDType';
import LanguagesSelect from '../../catalogs/LanguagesSelect';
import TimeZonesSelect from '../../catalogs/TimeZonesSelect';
import { useTranslation } from 'react-i18next';
import { formatDateToISO } from 'utils/cleanUtils';
import { useSelector } from 'react-redux';
import MarketsCatalog from 'components/common/catalogs/MarketsCatalog';
import { emptyUUID } from 'utils';
import SCheckBoxGroup from 'components/common/SCheckBoxGroup';
import { servicesTypeENUM } from 'models/enums';
import { sendNotification } from 'helpers/helperNotifications';
import { validateAddressProof } from 'utils/helperActions';
import { fileStatusEnum, userStatus } from 'models/enums';

const { TextArea } = Input;
function Step1Component(props) {
  let history = useHistory();
  const {
    form,
    readOnly,
    externalRegister,
    enableInputs,
    willBeUpdated,
    userId,
    activating,
    rejecting,
    type,
    changeStatus,
    formToState,
    next,
    saving,
    blockBtn,
    isRegister
  } = props;
  let { userIsLock, previousForm, canUpdateBuyers, disableUpdateOnValidation } = props;
  const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields } = form;
  const [startValue, setStartValue] = useState(null);
  const [personalsID, setPersonalsID] = useState({ isLoading: false });
  const [endOpen, setEndOpen] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');
  const { t } = useTranslation();
  const {userData} = useSelector(state => state.wizardForm);
  const markets = useSelector(state => state.catalogs.markets);
  const [insuranceTypes, setInsuranceTypes] = useState({ isLoading: false, data: [] });
  const [hasServiceVehicles, setHasServiceVehicles] = useState(false);
  const [hasDiverseServices, setHasDiverseServices] = useState(false);
  const proofAddress = useSelector((state) => state.users.proofAddress);
  const userDetail = useSelector(state => state.user.info);

  const isLoadingPersonalsID = personalsID.isLoading ? loadingSelect : '';
  previousForm =
    typeof previousForm.personalInfo !== 'undefined'
      ? { ...previousForm.personalInfo }
      : previousForm;
  let showLockReason =
    previousForm && previousForm.statusId === 0 && type !== 'register' ? true : false;
  showLockReason = externalRegister ? false : showLockReason;
  const [reasonLockRequired, setReasonLockRequired] = useState(false);
  const [isLock, setIsLock] = useState(showLockReason);
  canUpdateBuyers = typeof canUpdateBuyers !== 'undefined' ? canUpdateBuyers : false;

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    const current = showModal;
    setShowModal(!current);
  };

  useEffect(() => {
    let mounted = true;

    const getDocumentsGroups = async () => {
      try {
        setPersonalsID({
          isLoading: true
        });

        const responsePersonalsID = await listDocumentsByGroup(2);
        if (typeof responsePersonalsID !== 'undefined' && responsePersonalsID.status === 200) {
          if (mounted) {
            setPersonalsID(() => {
              return {
                data: responsePersonalsID.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setPersonalsID({
          isLoading: false
        });
      }
    };
    getDocumentsGroups();

    if(isRegister && userData && userData.personalInfo && userData.personalInfo.validFrom){
      form.setFieldsValue({
        ...userData.personalInfo,
        validFrom: moment(userData.personalInfo.validFrom).add(1, 'days'),
        validTo: moment(userData.personalInfo.validTo)
      });
    }

    const getInsuranceServiceTypes = async () => {
      try {
        setInsuranceTypes(old => ({
          ...old,
          isLoading: true
        }));
        const response = await getInsuranceTypes();
        if (mounted) {
          if (typeof response !== 'undefined' && response.status === 200) {
            setInsuranceTypes({
              data: response.data.data,
              isLoading: false
            });
          } else {
            setInsuranceTypes(() => {
              return {
                data: [],
                isLoading: false
              };
            });
          }
        }
      } catch (error) {
        if (mounted)
          setInsuranceTypes(() => {
            return {
              data: [],
              isLoading: false
            };
          });
      }
    };
    getInsuranceServiceTypes();

    return () => {
      if(!readOnly && props.onDismount) props.onDismount( formatDataToSave(props.form.getFieldsValue()), 0, 'phisicalBuyer' );
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if(previousForm && previousForm.services){
      onChangeServices(previousForm.services.map(s => s.id));
    }
  }, [previousForm]);
  

  const disabledEndDate = endValue => {
    if (!endValue || !startValue) {
      return false;
    }
    var start = moment(startValue).add(1, 'days');

    return endValue.valueOf() <= start.valueOf();
  };

  const onStartChange = value => {
    setStartValue(value);
  };

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open);
  };
  //useState useEffect
  const formatDataToSave = (values) => {
      return { personalInfo: { 
        ...values, 
        validFrom: isRegister || type === 'validate' ?  formatDateToISO(moment(values.validFrom).add(-1, 'days')) + 'T00:00:00': formatDateToISO(values.validFrom) + 'T00:00:00',
        validTo: formatDateToISO(values.validTo) + 'T00:00:00', 
        statusId: values.statusId ? 1 : 0,
        services : getServicesObject(values.services),
        } 
      }
  };

  useEffect(() => {
    setIsLock(showLockReason);
  }, [showLockReason]);

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleRejecting = e => {
    validateFields(async (err, values) => {
      console.log(err, values);
      if(!err){
        setShowComments(false);
        prepareDataToChangeStatus(e, 0);
      }
    });
  };

  const handleShowComments = () => {
    let current = showComments;
    setShowComments(!current);
  };

  const handleChangeComment = e => {
    let value = e.target.value;
    value = setFormatMarkets(value);
    console.log(value);
    setComments(value);
  };

  const createDefaultValues = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      let defaultValues = [];
      if (previousForm && previousForm.insuranceCarriers) {
        defaultValues = previousForm.insuranceCarriers.map(m => m.id);
        return defaultValues;
      } else {
        defaultValues = markets.map(m => m.id);
      }
      return defaultValues;
    }
  };

  const createMarketsCheckbox = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      return markets.map(m => {
        return {
          label: m.businessName,
          value: m.id,
          checked: true,
          defaultChecked: true
        };
      });
    }
  };

  const prepareDataToChangeStatus = (e, status) => {
    e.preventDefault();
    let user = {
      validFrom: type === 'validate' ? formatDateToISO(moment(getFieldValue('validFrom')).add(-1, 'days'))+ 'T00:00:00'  :  getFieldValue('validFrom'),
      validTo: getFieldValue('validTo'),
      insuranceCarriers: getFieldValue('insuranceCarriers'),
      markets,
      statusId: status,
      comments: comments,
      services : getServicesObject(getFieldValue('services')),
    };
    changeStatus(user);
  };

  const saveDataToStore = (values, saveAsLastStep) => {
    const dataToSave = formatDataToSave(values);
    formToState(dataToSave, 0, saveAsLastStep, 'Buyers', undefined, userIsLock);
  };

  const handleSubmitFinal = (e) => {
    if(validateAddressProof(proofAddress, userData?.personalInfo?.statusId == userStatus.INACTIVE)){
      validateFields(async (err, values) => {
        if (!err) {
          saveDataToStore(values, true);
          if (blockBtn) blockBtn();
        }
      });
    }
  };

  const handleSubmit =() =>{
    validateFields(async (err, values) => {
      if (!err) {
        saveDataToStore(values, false);
        if (blockBtn) blockBtn();
        next();
      }
    })
  }

  const handleFooterActionButtons = action => {
    if (action === 'cancel') {
      history.push(setToPageCancel());
    }
    if (action === 'update') {
      enableInputs();
    }
    if(action === 'next'){
      handleSubmit();
    }
    if(action == 'submit'){
      handleSubmitFinal();
    }
  };

  const formatDisabledCheckbox = useCallback(() => {
    if (insuranceTypes && insuranceTypes.data.length > 0) {
        return insuranceTypes.data;
    }
  }, [insuranceTypes, previousForm.services, isRegister]);

  const onChangeServices = useCallback(services => {
    let list = [];
    let foundVehicleServices = services.includes(servicesTypeENUM.VEHICULOS);
    setHasServiceVehicles(foundVehicleServices);
    if(foundVehicleServices)
      list.push(servicesTypeENUM.VEHICULOS);
    let foundDiverseServices = services.includes(servicesTypeENUM.DIVERSOS);
    setHasDiverseServices(foundDiverseServices);
    if(foundDiverseServices)
      list.push(servicesTypeENUM.DIVERSOS);
    setFieldsValue({services: list});
  }, []);

  const getServicesObject = (services) => {
    let servicesObject = [];
    if(hasServiceVehicles){
      servicesObject.push({id: servicesTypeENUM.VEHICULOS});
    }
    if(hasDiverseServices){
      servicesObject.push({id: servicesTypeENUM.DIVERSOS});
    }

    if(!hasDiverseServices && !hasServiceVehicles && services?.length > 0){
      servicesObject = services.map(s => ({id: s}));
    }
    return servicesObject;
  };

  return (
    <Form noValidate layout="vertical" onSubmit={handleSubmitFinal} jest-id="form">
      <Row gutter={24}>
        <STitle className="wizard-title" level={4} titleText={t('usersform.personalinfo')} />

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.name')}>
            {getFieldDecorator('givenName', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Nombre(s)')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.givenName ? previousForm.givenName : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.firstlastname')}>
            {getFieldDecorator('surname', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Primer apellido')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.surname ? previousForm.surname : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.secondlastname')}>
            {getFieldDecorator('lastname', {
              rules: [setRuleMin(2), setRuleMax(50)],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.lastname ? previousForm.lastname : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.businessname')}>
            {getFieldDecorator('businessName', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired(t('usersform.businessname'))],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.businessName ? previousForm.businessName : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={11} lg={8} xl={8}>
          <Form.Item label={t('usersform.bussinesemail')}>
            {getFieldDecorator('email', {
              rules: [
                setRuleEmail('Correo electrónico empresarial'),
                setRuleRequired('Correo electrónico empresarial'),
                setRuleMax(80)
              ],
              normalize: value => removeAccent(value),
              initialValue: previousForm.email ? previousForm.email : ''
            })(
              <Input
                disabled={readOnly || externalRegister || isLock}
                placeholder={t('adminforms.step2.user')}
                autoComplete="none"
                type="email"
                maxLength={80}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.birth')}>
            {getFieldDecorator(
              'bornDate',
              configValidBorn(previousForm)
            )(
              <DatePicker
                disabled={readOnly || isLock}
                format={dateFormat}
                disabledDate={date => disabledFutureDays(date)}
                placeholder={t('system.select')}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.rfc')}>
            {getFieldDecorator('taxId', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    setInvalidFormat('RFC', 'RFC', value, callback);
                  }
                },
                setRuleRequired('RFC')
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.taxId ? previousForm.taxId : ''
            })(
              <Input
                disabled={readOnly || isLock}
                autoComplete="off"
                type="string"
                minLength={11}
                maxLength={13}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.curp')}>
            {getFieldDecorator('curp', {
              rules: [
                setRuleRequired('CURP'),

                {
                  validator: (rule, value, callback) => {
                    setInvalidFormat('CURP', 'CURP', value, callback);
                  }
                }
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.curp ? previousForm.curp : ''
            })(
              <Input
                disabled={readOnly || isLock}
                autoComplete="off"
                minLength={18}
                maxLength={18}
              />
            )}
          </Form.Item>
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <SIDType
            form={form}
            {...isLoadingPersonalsID}
            initialValue={previousForm.documentTypeId ? previousForm.documentTypeId : null}
            disabled={readOnly || isLock}
            personalsID={personalsID}
          />
        </Col>

        <Col sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={t('usersform.idfolio')}>
            {getFieldDecorator('documentNumber', {
              rules: [
                setRuleRequired('Número de Folio de identificación'),
                setRuleMin(2),
                setRuleMax(20)
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.documentNumber ? previousForm.documentNumber : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>
        <Col {...layoutInputs}>
          <LanguagesSelect
            form={form}
            disabled={readOnly || isLock}
            initialValue={previousForm.languageId && previousForm.languageId != emptyUUID ? previousForm.languageId : null}
          />
        </Col>

        <Col {...layoutInputs}>
          <TimeZonesSelect
            form={form}
            disabled={readOnly || isLock}
            initialValue={previousForm.timeZoneId && previousForm.timeZoneId != emptyUUID ? previousForm.timeZoneId : null}
          />
        </Col>

        {!externalRegister && (
          <>
            {type === 'validate' && (
              <>
                <Col sm={12} md={12} lg={8} xl={8}>
                  <Form.Item label={t('usersform.entrydate')}>
                    {getFieldDecorator(
                      'validFrom',
                      configValidFrom(previousForm)
                    )(
                      <DatePicker
                        jest-id="startDate"
                        disabled={false}
                        placeholder={t('system.select')}
                        format={dateFormat}
                        disabledDate={date => disabledPastDays(date)}
                        onChange={date => {
                          onStartChange(date);
                        }}
                        onOpenChange={handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col sm={12} md={12} lg={8} xl={8}>
                  <Form.Item label={t('usersform.terminationdate')}>
                    {getFieldDecorator(
                      'validTo',
                      configValidTo(previousForm)
                    )(
                      <DatePicker
                        jest-id="endDate"
                        disabled={false}
                        format={dateFormat}
                        disabledDate={date => disabledEndDate(date)}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                        placeholder={t('system.select')}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            {type !== 'validate' && (
              <>
                <Col sm={12} md={12} lg={8} xl={8}>
                  <Form.Item label={t('usersform.entrydate')}>
                    {getFieldDecorator(
                      'validFrom',
                      configValidFrom(previousForm)
                    )(
                      <DatePicker
                        disabled={readOnly || isLock}
                        format={dateFormat}
                        placeholder={t('system.select')}
                        disabledDate={date => disabledPastDays(date)}
                        onChange={date => {
                          onStartChange(date);
                        }}
                        onOpenChange={handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col sm={12} md={12} lg={8} xl={8}>
                  <Form.Item label={t('usersform.terminationdate')}>
                    {getFieldDecorator(
                      'validTo',
                      configValidTo(previousForm)
                    )(
                      <DatePicker
                        disabled={readOnly || isLock}
                        format={dateFormat}
                        disabledDate={date => disabledEndDate(date)}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                        placeholder={t('system.select')}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}
          </>
        )}

        {willBeUpdated && !readOnly && (
          <Col lg={24} md={24}>
            <Col {...layoutInputs}>
              <SSwitch
                isRequired={true}
                label={t('insuranceform.step1.changeuserstatus')}
                inputKey="statusId"
                unCheckedChildren="Inactivo"
                checkedChildren="Activo"
                onChange={value => {
                  setIsLock(!value);
                  setReasonLockRequired(true);
                  setFieldsValue({
                    lockReason: t('managementLock')
                  });
                }}
                initialValue={
                  previousForm && previousForm.statusId && previousForm.statusId === 1
                    ? true
                    : false
                }
                form={form}
              />
            </Col>

            {isLock && (
              <>
                <Col {...layoutInputs}>
                  <Form.Item label={t('users.blockingreason')}>
                    {getFieldDecorator('lockReason', {
                      initialValue:
                        previousForm && previousForm.lockReason
                          ? previousForm.lockReason
                          : t('managementLock'),
                      rules: [{ required: true }],
                      normalize: value => setFormatMarkets(value),
                    })(<Input disabled={true} type="string" />)}
                  </Form.Item>
                </Col>
              </>
            )}

            {reasonLockRequired && (
              <Col {...layoutInputs}>
                <SInput
                  label={t('usersform.comments')}
                  disabled={readOnly}
                  isRequired={reasonLockRequired}
                  form={form}
                  inputKey="comments"
                  initialValue={''}
                  inputType="textarea"
                  maxLength={120}
                  minRequired={2}
                  rows={3}
                  normalize='formatMarkets'
                />
              </Col>
            )}
          </Col>
        )}

        {type === 'validate' && (
          <Col sm={12} md={12} lg={8} xl={8}>
            <MarketsCatalog
                    label={t('table.insurance')}
                    isRequired={true}
                    form={form}
                    inputKey="insuranceCarriers"
                    catalogType="checkbox"
                    withAllOption={true}
                    isModeTagsUsed={true}
                    mode="multiple"
                    cbxHeight={200}
                    />
          </Col>
        )}

        {type !== 'validate' && (
          <>
            <Col sm={12} md={12} lg={8} xl={8}>
              <Form.Item label={t('usersform.speedonline')}>
                {getFieldDecorator('foreignUserId', {
                  rules: [setRuleMin(1), setRuleMax(8)],
                  normalize: value => setFormatMarkets(value),
                  initialValue: previousForm.foreignUserId ? previousForm.foreignUserId : ''
                })(
                  <Input
                    disabled={readOnly || isLock}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    minLength={1}
                    maxLength={8}
                  />
                )}
              </Form.Item>
            </Col>
            {readOnly && (
              <InfoUsersOnReadOnly
                previousForm={previousForm}
                form={form}
                readOnly={readOnly || isLock}
              />
            )}
          </>
        )}

        {
          !externalRegister &&(
            <Col sm={12} md={12} lg={8} xl={8}>
              <SCheckBoxGroup
                jest-id="check"
                form={form}
                inputKey="services"
                isRequired
                disabled={readOnly && type !== 'validate'}
                label={t('catalogs.detail.insurancetype')}
                options={formatDisabledCheckbox()}
                onChange={onChangeServices}
                keyToDisabled="disabled"
                optionsByDefault={!isRegister ? previousForm.services : []}
                style={{
                  width: '100%',
                  padding: '5px',
                  textAlign: 'left'
                }}
              />
            </Col>
          )
        }

        {externalRegister && (
          <>
            <Col sm={24} md={20} lg={20} xl={20} style={{ marginLeft: '40px' }}>
              <Form.Item className="text-left">
                {getFieldDecorator('acceptTerms', {
                  valuePropName: 'checked',
                  initialValue:
                    previousForm && previousForm.acceptTerms ? previousForm.acceptTerms : false,
                  rules: [
                    {
                      required: true,
                      transform: value => value || undefined,
                      type: 'boolean',
                      message: `El campo términos y condiciones es obligatorio`
                    }
                  ]
                })(
                  <Checkbox className="text-center">
                    <span>Acepto</span>
                  </Checkbox>
                )}
                <SButton type="link" label={t('loginform.termscondition')} onClick={handleShowModal} />
              </Form.Item>
            </Col>
            <ModalWithTermsAndConditions handleShowModal={handleShowModal} showModal={showModal} />
          </>
        )}
      </Row>

      {type !== 'validate' && (
        <FooterWizardActions
          jest-id="actions"
          meta={'Personal Buyer Form - Step 1'}
          onCancel
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
          onSubmit={{ show: !readOnly && !isRegister && !externalRegister, loading: saving, disabled: saving }}
          onNext={{show: !readOnly && (isRegister || externalRegister), loading: saving, disabled: saving}}
          handleAction={handleFooterActionButtons}
        />
      )}

      {type === 'validate' && (
        <>
          <Modal
            jest-id="modal"
            title={<STitle level={4} titleText={t('insuredforms.addcomments')} />}
            visible={showComments}
            onOk={e => handleRejecting(e)}
            okText={t('system.acept')}
            cancelText={t('system.cancel')}
            onCancel={handleShowComments}
          >
            <TextArea
              onChange={handleChangeComment}
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder={t('insuredforms.step1.describe')}
              value={comments}
            />
          </Modal>
          <div className="steps-action float-right">
            <MyLink type="default" label={t('system.cancel')} to={mainHome} />

            <SButton
              loading={rejecting}
              disabled={rejecting}
              type="primary"
              label={rejecting ? t('system.rejecting') : t('system.reject')}
              onClick={handleShowComments}
            />

            <SButton
              jest-id="modalBtn"
              loading={activating}
              label={activating ? t('system.activating') : t('system.activate')}
              disabled={activating}
              type="primary"
              onClick={e => prepareDataToChangeStatus(e, 1)}
            />
          </div>
        </>
      )}
    </Form>
  );
}

const Step1 = Form.create({ name: 'add-personal-buyer-user' })(Step1Component);

Step1.propTypes = {
  readOnly: PropTypes.bool,
  willBeUpdated: PropTypes.bool,
  externalRegister: PropTypes.bool,
  isRegister: PropTypes.bool
};

Step1.defaultProps = {
  readOnly: false,
  type: 'register',
  willBeUpdated: false,
  externalRegister: false,
  isRegister: false
};

export default Step1;
