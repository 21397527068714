import React from 'react';
import PropTypes from 'prop-types';
import SSelect from 'components/common/SSelect';
import { useTranslation } from 'react-i18next';

function SIDType(props) {
  const { form, initialValue, personalsID, ...other } = props;
  const { t } = useTranslation();

  return (
    <SSelect
      form={form}
      initialValue={initialValue}
      inputKey="documentTypeId"
      isLoading={personalsID?.isLoading}
      label={t('usersform.identificationtype')}
      optionKey="id"
      isRequired={true}
      options={personalsID?.data}
      placeholder={t('system.select')}
      valueKey="id"
      {...other}
    />
  );
}

SIDType.propTypes = {
  initialValue: PropTypes.any,
  personalsID: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestAdvertStatus: PropTypes.func
};

SIDType.defaultProps = {
  initialValue: undefined,
  personalsID: { data: [], isLoading: false },
  requestAdvertStatus: null
};

export default SIDType;
