import React from 'react';
import PropTypes from 'prop-types';
const styleButton = {
  cursor: 'pointer',
  display: 'inline-block',
  marginRight: 24,
  marginTop: 0,
  fontWeight: 700,
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none'
};
export const CustomPrevButton = props => {
  const { handlePrevClick, page } = props;
  if (page === 1) return <div />;
  return (
    <button style={styleButton} onClick={handlePrevClick}>
      <i className="material-icons">keyboard_arrow_left</i>
    </button>
  );
};
CustomPrevButton.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired
};

export const CustomNextButton = props => {
  const { page, pages, handleNextClick } = props;
  if (page === pages) return <div />;

  return (
    <button style={styleButton} onClick={handleNextClick}>
      <i className="material-icons">keyboard_arrow_right</i>
    </button>
  );
};
CustomNextButton.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handleNextClick: PropTypes.func.isRequired
};

export const CustomPages = props => {
  const { page, pages } = props;
  return (
    <h3 style={{ display: 'inline-block', marginTop: 0 }}>
      Página {page} de {pages}
    </h3>
  );
};
CustomPages.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired
};

// export const CustomZoomIn = ({ ZoomClickHandler, scale, handleZoomIn }) => {
//   console.log('handleZoomI :>> ', handleZoomIn);

//   return <Button icon="zoom-in" onClick={handleZoomIn} />;
// };

// export const CustomZoomOut = ({ ZoomClickHandler, scale, handleZoomOut }) => {
//   console.log('typeof handleZoomOut :>> ', typeof handleZoomOut);
//   return <Button icon="zoom-out" onClick={handleZoomOut} />;
// };

const CustomNavigation = props => {
  const { page, pages, handlePrevClick, handleNextClick } = props;

  return (
    <div className="customWrapper">
      {/* <CustomZoomIn handleZoomIn={handleZoomIn} ZoomClickHandler={ZoomClickHandler} scale={scale} />
      <CustomZoomOut
        ZoomClickHandler={ZoomClickHandler}
        handleZoomOut={handleZoomOut}
        scale={scale}
      /> */}
      <CustomPrevButton page={page} pages={pages} handlePrevClick={handlePrevClick} />
      <CustomPages page={page} pages={pages} />
      <CustomNextButton page={page} pages={pages} handleNextClick={handleNextClick} />
    </div>
  );
};
CustomNavigation.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired
};

export default CustomNavigation;
