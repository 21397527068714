import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import EmptyGallery from 'assets/img/states/emptyGallery.svg';
import EmptyTable from 'assets/img/states/emptyTable.svg';
import EmptyFiles from 'assets/img/states/emptyFiles.svg';
import './style.css';
import { useTranslation } from 'react-i18next';

function SEmpty(props) {
  const { description, type } = props;
  const {t} = useTranslation();

  const custom_MSG_20 = [
    <strong key="not-results">{t('MSG_020')}</strong>,
    t('MSG_020_1')
  ];
  let message = description && description !== '' ? description : custom_MSG_20;

  const getEmptyType = () => {
    let img = '';
    let idTest = 'default';
    switch (type) {
      case 'gallery':
        img = EmptyGallery;
        idTest = type;
        break;
      case 'table':
        img = EmptyTable;
        idTest = type;
        break;
      case 'files':
        img = EmptyFiles;
        idTest = type;
        break;
      default:
        img = EmptyTable;
    }
    return <img data-testid={idTest} src={img} alt={message} />;
  };

  return (
    <Empty
      className="empty-component"
      image={getEmptyType()}
      style={{ margin: '.6rem' }}
      description={message}
    />
  );
}

export default SEmpty;
SEmpty.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.oneOf(['list', 'gallery', 'table', 'files'])
};

SEmpty.defaultProps = {
  type: 'table',
  description: ''
};
