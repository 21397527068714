import React, { forwardRef } from 'react';
import { Checkbox, Form } from 'antd';
import PropTypes from 'prop-types';
import { setRuleRequired } from '../../../utils/validations';

/**
 * SCheckBoxGroup is an encapsulation of Ant Desing Checkbox Group
 *  🧪 Ccmponent with 100% coverage
 */

const SCheckBoxGroup = forwardRef((props, ref) => {
  const {
    label,
    inputKey,
    isRequired,
    labelKey,
    valueKey,
    keyToDisabled,
    disabled,
    options,
    optionsByDefault,
    form,
    cbxHeight,
    ...other
  } = props;
  const { getFieldDecorator } = form;

  const createOptionsGroup = () => {
    if (typeof options !== 'undefined' && options?.length > 0) {
      return options.map(option => {
        if (keyToDisabled) {
          return {
            label: option[labelKey],
            value: option[valueKey],
            disabled: option[keyToDisabled],
            title: option[labelKey],
            checked: true,
            defaultChecked: true
          };
        } else {
          return {
            label: option[labelKey],
            value: option[valueKey],
            title: option[labelKey],
            checked: true,
            defaultChecked: true
          };
        }
      });
    }
  };

  const createDefaultValues = () => {
    if (
      typeof optionsByDefault !== 'undefined' &&
      optionsByDefault &&
      optionsByDefault.length > 0
    ) {
      return optionsByDefault.map(option => option[valueKey]);
    }
  };

  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <>
      <Form.Item label={label}>
        {getFieldDecorator(inputKey, {
          rules: [...requiredRule],
          initialValue: createDefaultValues()
        })(
          <Checkbox.Group
            ref={ref}
            style={{
              width: '100%',
              height: cbxHeight ? cbxHeight : '100px',
              border: '0.1px solid rgba(112,113,115,0.2)',
              padding: '5px',
              textAlign: 'left',
              border: '2px solid #ccc',
              overflowY: 'scroll'
            }}
            options={createOptionsGroup()}
            disabled={disabled}
            {...other}
          />
        )}
      </Form.Item>
    </>
  );
});

SCheckBoxGroup.propTypes = {
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  inputKey: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  keyToDisabled: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  optionsByDefault: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object.isRequired
};

SCheckBoxGroup.defaultProps = {
  disabled: false,
  isRequired: false,
  label: 'Label',
  options: [],
  labelKey: 'name',
  valueKey: 'id',
  inputKey: 'checkboxGroup',
  keyToDisabled: undefined,
  optionsByDefault: []
};

SCheckBoxGroup.displayName = 'SCheckBoxGroup';

export default SCheckBoxGroup;
