import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

function MyButton(props) {
  const { label, shape, ...other } = props;
  return (
    <Button shape={shape} {...other}>
      {label}
    </Button>
  );
}

MyButton.propTypes = {
  /* Label to be rendered in the Button */
  label: PropTypes.string,

  /* Shape of the Button */
  shape: PropTypes.oneOf(['round', 'circle', null]),

  /* Other propierties suported by antd API that will be injected in the button */
  other: PropTypes.element
};

MyButton.defaultProps = {
  label: '',
  shape: 'round',
  other: null
};

export default MyButton;
