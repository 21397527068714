import React from 'react';
import { Modal } from 'antd';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearModalPage } from './../../../redux/actions/caseActions';

function MyModal(props) {
  const {
    title,
    component: MyComponent,
    htmlContent,
    show,
    handleShow,
    footer,
    isLoading,
    width,
    onOk,
    onCancel,
    destroyOnClose,
    className,
    cancelText,
    clearModalPage: clearModalPageAction,
    afterClose,
    ...others
  } = props;

  const renderComponent = () => {
    /* istanbul ignore else */
    if (isLoading === false && typeof MyComponent === 'function') {
      return <MyComponent data-testid="custom-component" />;
    } else {
      return null;
    }
  };

  return (
    <Modal
      width={width}
      id="modal-id"
      className={className}
      destroyOnClose={destroyOnClose}
      title={title}
      maskClosable={false}
      footer={footer}
      cancelText={cancelText}
      visible={show}
      onOk={onOk ? onOk : handleShow}
      onCancel={onCancel ? onCancel : handleShow}
      afterClose={afterClose ? afterClose : clearModalPageAction} //clearModalPageAction}
      {...others}
    >
      {isLoading && <Loader isPageLoader size={32} />}
      {renderComponent()}
      {isLoading === false && htmlContent !== '' && (
        <div data-testid="custom-html">{htmlContent}</div>
      )}
    </Modal>
  );
}

MyModal.propTypes = {
  /* The modal dialog's title */
  title: PropTypes.node,

  /* Footer content */
  footer: PropTypes.any,

  /* Width of the modal dialog */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /* Whether the modal dialog is visible or not */
  show: PropTypes.bool,

  /* Whether the modal is loading something or not */
  isLoading: PropTypes.bool,

  /* Callback triggered when a user clicks the OK button or the clicks mask, close button on top right or Cancel button */
  handleShow: PropTypes.func,

  /* Defined children who expects HMTL */
  htmlContent: PropTypes.any,

  /* Defined children who expects node */
  component: PropTypes.any,

  clearModalPage: PropTypes.func,
  destroyOnClose: PropTypes.bool,
  onOk: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onCancel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  cancelText: PropTypes.string,
  className: PropTypes.string
};

MyModal.defaultProps = {
  title: 'Modal Title',
  width: 520,
  show: false,
  isLoading: false,
  className: 'my-modal-size',
  component: null,
  handleShow: null,
  htmlContent: '',
  clearModalPage: null,
  destroyOnClose: true,
  footer: <div key="footer" style={{ margin: '.5rem', width: '100%', height: '.5rem' }} />,
  onOk: false,
  onCancel: false,
  cancelText: 'Cancelar'
};

export default connect(null, { clearModalPage })(MyModal);
