import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Form, Input, Typography, Col, Row, DatePicker, Checkbox, Modal } from 'antd';
import MyButton from 'components/common/MyButton';
import {
  setRuleRequired,
  setRuleMin,
  setRuleMax,
  setCapitalLetter,
  disabledPastDays,
  disabledFutureDays,
  setInvalidFormat,
  setUpperCase,
  setFormatMarkets
} from 'utils/validations';
import { mainHome, dateFormat } from 'utils/constants';
import {
  configValidFrom,
  configValidCreated,
  configValidTo,
  loadingSelect,
  layoutInputs
} from 'helpers/helperForms';
import STitle from 'components/common/STitle';
import MyLink from 'components/common/MyLink';
import SButton from 'components/common/SButton';
import SAddressType from 'components/common/SAddressType';
import AddressInputs from 'components/common/AddressInputs';
import FooterWizardActions from 'components/common/FooterWizardActions';
import InfoUsersOnReadOnly from 'components/common/InfoUsersOnReadOnly';
import SInput from 'components/common/SInput';
import ModalWithTermsAndConditions from 'components/common/ModalWithTermsAndConditions';
import SSwitch from 'components/common/SSwitch';
import { getInsuranceTypes, listAddressType } from 'services/catalogs';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RegimeSelect from 'components/common/catalogs/RegimeSelect';
import { formatDateToISO } from 'utils/cleanUtils';
import { useSelector } from 'react-redux';
import MarketsCatalog from 'components/common/catalogs/MarketsCatalog';
import { servicesTypeENUM } from 'models/enums';
import SCheckBoxGroup from 'components/common/SCheckBoxGroup';
import { validateAddressProof } from 'utils/helperActions';
import { userStatus } from 'models/enums';

const { Title } = Typography;
const { TextArea } = Input;

function Step1Component(props) {
  let history = useHistory();
  const {
    activating,
    changeStatus,
    enableInputs,
    externalRegister,
    form,
    readOnly,
    rejecting,
    type,
    userId,
    willBeUpdated,
    canUpdateBuyers,
    disableUpdateOnValidation,
    currentProfile,
    saving,
    formToState,
    blockBtn,
    next
  } = props;
  let { previousForm, isRegister, userIsLock } = props;
  const { getFieldDecorator, getFieldValue, validateFields, setFieldsValue } = form;
  const [startValue, setStartValue] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');
  const [endOpen, setEndOpen] = useState(false);
  const [addressType, setAddressType] = useState({ isLoading: false });
  const [regimeId, setRegimeId] = useState(null);
  const isLoadingAddress = addressType.isLoading ? loadingSelect : '';
  const { t } = useTranslation();
  const {userData} = useSelector(state => state.wizardForm);
  const markets = useSelector(state => state.catalogs.markets);
  const [insuranceTypes, setInsuranceTypes] = useState({ isLoading: false, data: [] });
  const [hasServiceVehicles, setHasServiceVehicles] = useState(false);
  const [hasDiverseServices, setHasDiverseServices] = useState(false);
  const proofAddress = useSelector((state) => state.users.proofAddress);

  previousForm = typeof previousForm !== 'undefined' ? previousForm : false;
  previousForm =
    previousForm && previousForm.companyInfo ? { ...previousForm.companyInfo } : previousForm;
  let showLockReason = previousForm && previousForm.statusId === 0 && !isRegister ? true : false;
  showLockReason = externalRegister ? false : showLockReason;
  const [reasonLockRequired, setReasonLockRequired] = useState(false);
  const [isLock, setIsLock] = useState(showLockReason);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    const current = showModal;
    setShowModal(!current);
  };

  useEffect(() => {
    if(isRegister && userData && userData.companyInfo && userData.companyInfo.validFrom){
      form.setFieldsValue({
        ...userData.companyInfo,
        validFrom: moment(userData.companyInfo.validFrom).add(1, 'days'),
        validTo: moment(userData.companyInfo.validTo)
      });
    }

    return () => {
      if(!readOnly && props.onDismount) props.onDismount( formatDataToSave(props.form.getFieldsValue()), 0, 'moralBuyer' );
    }
  }, [])
  

  useEffect(() => {
    let mounted = true;
    const getAddress = async () => {
      try {
        setAddressType({
          isLoading: true
        });

        const response = await listAddressType(externalRegister ? i18n.language : false);
        if (typeof response !== 'undefined' && response.status === 200) {
          if (mounted) {
            setAddressType(() => {
              return {
                data: response.data.data,
                isLoading: false
              };
            });
          }
        }
      } catch (_error) {
        setAddressType({ isLoading: false });
      }
    };
    getAddress();

    const getInsuranceServiceTypes = async () => {
      try {
        setInsuranceTypes(old => ({
          ...old,
          isLoading: true
        }));
        const response = await getInsuranceTypes();
        if (mounted) {
          if (typeof response !== 'undefined' && response.status === 200) {
            setInsuranceTypes({
              data: response.data.data,
              isLoading: false
            });
          } else {
            setInsuranceTypes(() => {
              return {
                data: [],
                isLoading: false
              };
            });
          }
        }
      } catch (error) {
        if (mounted)
          setInsuranceTypes(() => {
            return {
              data: [],
              isLoading: false
            };
          });
      }
    };
    getInsuranceServiceTypes();
        
    return () => {
      mounted = false;
    };
  }, []);

  const disabledEndDate = endValue => {
    if (!endValue || !startValue) {
      return false;
    }
    var start = moment(startValue).add(1, 'days');

    return endValue.valueOf() <= start.valueOf();
  };

  const onStartChange = value => {
    setStartValue(value);
  };

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open);
  };

  const formatDataToSave = values => {
        let companyInfo = {
          name: values.name,
          regimeId: values.regimeId,
          businessName: values.businessName,
          taxId: values.taxId,
          businessType: values.businessType,
          activityLine: values.activityLine,
          created: values.created,
          fielNumber: values.fielNumber,
          commerceSerial: values.commerceSerial,
          addressTypeId: values.addressTypeId,
          validFrom:  isRegister || type === 'validate' ?  formatDateToISO(moment(values.validFrom).add(-1, 'days'))+ 'T00:00:00' : formatDateToISO(values.validFrom)+ 'T00:00:00',
          validTo: formatDateToISO(values.validTo) + 'T00:00:00',
          statusId: values.statusId ? 1 : 0,
          foreignUserId: values.foreignUserId,
          comments: values.comments ? values.comments : '',
          services: getServicesObject(values.services),
        };

        if (externalRegister) {
          companyInfo = { ...companyInfo, acceptTerms: values.acceptTerms };
        }

        let objAddress = {
          address: {
            roadName: values.roadName,
            outNumber: values.outNumber,
            inNumber: values.inNumber,
            neighborhood: values.neighborhood,
            town: values.town,
            city: values.city,
            stateId: values.stateId,
            countryId: values.countryId,
            zipCode: values.zipCode
          }
        };

        let withAddress = {
          ...companyInfo,
          ...objAddress
        };

        return {
          companyInfo: { ...withAddress, statusId: values.statusId ? 1 : 0 } 
        }
  };

  useEffect(() => {
    setIsLock(showLockReason);
  }, [showLockReason]);

  const auxFieldNumberSerieFIEL = [setRuleMin(2), setRuleMax(20)];

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleShowComments = () => {
    let current = showComments;
    setShowComments(!current);
  };

  const handleChangeComment = e => {
    let value = e.target.value;
    value = setFormatMarkets(value);
    setComments(value);
  };

  const handleRejecting = e => {
    form.validateFields( async (err, values) => {
      console.log(err);
      if(!err){
        setShowComments(false);
        prepareDataToChangeStatus(e, 0);
      }
    });
  };

  const prepareDataToChangeStatus = (e, status) => {
    e.preventDefault();
    let user = {
      validFrom: type === 'validate' ? formatDateToISO(moment(getFieldValue('validFrom')).add(-1, 'days'))+ 'T00:00:00'  :  getFieldValue('validFrom'),
      validTo: getFieldValue('validTo'),
      statusId: status,
      insuranceCarriers: getFieldValue('insuranceCarriers'),
      markets,
      comments: comments,
      services : getServicesObject(getFieldValue('services')),
    };
    changeStatus(user);
  };

  const handleFooterActionButtons = action => {
    if (action === 'cancel') {
      history.push(setToPageCancel());
    }
    if (action === 'update') {
      enableInputs();
    }
    if( action === 'next'){
      handleSubmit();
    }
    if (action === 'submit') {
      handleSubmitFinal();
    }
  };

  const handleRegime = (e) =>{
    setRegimeId(e);
  }
  const saveDataToStore = (values, saveAsLastStep) => {
    const dataToSave = formatDataToSave(values);
    formToState(dataToSave, 0, saveAsLastStep, 'Buyers/BuyerCompany', undefined, userIsLock);
  };

  const handleSubmitFinal = () => {
    if(validateAddressProof(proofAddress, userData?.companyInfo?.statusId == userStatus.INACTIVE)){
      validateFields(async (err, values) => {
        if (!err) {
          saveDataToStore(values, true);
          if (blockBtn) blockBtn();
        }
      });
    }
   
  };

  const handleSubmit = ()=>{
    validateFields(async (err, values) => {
      if (!err) {
        saveDataToStore(values, false);
        if (blockBtn) blockBtn();
        next();
      }
    });
  }

  const formatDisabledCheckbox = useCallback(() => {
    if (insuranceTypes && insuranceTypes.data.length > 0) {
        return insuranceTypes.data;
    }
  }, [insuranceTypes, previousForm.services, isRegister]);

  const onChangeServices = useCallback(services => {
    let list = [];
    let foundVehicleServices = services.includes(servicesTypeENUM.VEHICULOS);
    setHasServiceVehicles(foundVehicleServices);
    if(foundVehicleServices)
      list.push(servicesTypeENUM.VEHICULOS);
    let foundDiverseServices = services.includes(servicesTypeENUM.DIVERSOS);
    setHasDiverseServices(foundDiverseServices);
    if(foundDiverseServices)
      list.push(servicesTypeENUM.DIVERSOS);
    setFieldsValue({services: list});
  }, []);

  const getServicesObject = (services) => {
    let servicesObject = [];
    if(hasServiceVehicles){
      servicesObject.push({id: servicesTypeENUM.VEHICULOS});
    }
    if(hasDiverseServices){
      servicesObject.push({id: servicesTypeENUM.DIVERSOS});
    }

    if(!hasDiverseServices && !hasServiceVehicles && services?.length > 0){
      servicesObject = services.map(s => ({id: s}));
    }
    
    return servicesObject;
  };

  return (
    <Form noValidate className="text-left" onSubmit={handleSubmitFinal} jest-id="form">
      <Row gutter={24} className="row-forms">
        <STitle titleText={t('usersform.businessinfo')} className="wizard-title" level={4} />

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.businessname')}>
            {getFieldDecorator('businessName', {
              //businessName
              rules: [setRuleMin(2), setRuleMax(100), setRuleRequired('Nombre comercial')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.businessName ? previousForm.businessName : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={100}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.companyname')}>
            {getFieldDecorator('name', {
              rules: [setRuleMin(2), setRuleMax(80), setRuleRequired('Razón Social')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.name ? previousForm.name : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                minLength={2}
                maxLength={80}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.rfc')}>
            {getFieldDecorator('taxId', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    setInvalidFormat('RFCMORAL', 'RFC', value, callback);
                  }
                },
                setRuleRequired('RFC')
              ],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.taxId ? previousForm.taxId : ''
            })(
              <Input
                disabled={readOnly || isLock}
                autoComplete="off"
                type="string"
                minLength={11}
                maxLength={12}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
            <Form.Item label={t('usersform.activity')}>
              {getFieldDecorator('activityLine', {
                rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Giro o Regimen')],
                normalize: value => setFormatMarkets(value),
                initialValue: previousForm && previousForm.activityLine ? previousForm.activityLine : ''
              })(
                <Input
                  disabled={readOnly || isLock}
                  placeholder=""
                  autoComplete="off"
                  type="string"
                  minLength={2}
                  maxLength={50}
                />
              )}
            </Form.Item>
          </Col>

        <Col {...layoutInputs}>
          <RegimeSelect 
            form={form}
            disabled={readOnly || isLock}
            currentProfile={currentProfile}
            onChange={handleRegime}
            initialValue={previousForm.regimeId ? previousForm.regimeId : null}
          />
        </Col>

        { regimeId == 1 && (
          <Col {...layoutInputs}>
            <Form.Item label={t('usersform.otherregime')}>
              {getFieldDecorator('businessType', {
                rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Giro o Regimen')],
                normalize: value => setFormatMarkets(value),
                initialValue: previousForm && previousForm.businessType ? previousForm.businessType : ''
              })(
                <Input
                  disabled={readOnly || isLock}
                  placeholder=""
                  autoComplete="off"
                  type="string"
                  minLength={2}
                  maxLength={50}
                />
              )}
            </Form.Item>
          </Col>
        ) }

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.incorporation')}>
            {getFieldDecorator(
              'created',
              configValidCreated(previousForm)
            )(
              <DatePicker
                jest-id="datepicker"
                disabled={readOnly || isLock}
                format={dateFormat}
                disabledDate={date => disabledFutureDays(date)}
                placeholder={t('system.select')}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.fiel')}>
            {getFieldDecorator('fielNumber', {
              rules: !isLock ? [...auxFieldNumberSerieFIEL] : [],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.fielNumber ? previousForm.fielNumber : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.commercialfolio')}>
            {getFieldDecorator('commerceSerial', {
              rules: [setRuleMin(2), setRuleMax(20), setRuleRequired('Folio Mercantil')],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.commerceSerial ? previousForm.commerceSerial : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <SAddressType
            form={form}
            {...isLoadingAddress}
            addressType={addressType}
            disabled={readOnly || isLock}
            initialValue={previousForm.addressTypeId ? previousForm.addressTypeId : null}
          />
        </Col>
        {/* start address */}
        <AddressInputs
          readOnly={readOnly || isLock}
          previousForm={previousForm}
          fieldsNotRequired={['state', 'inNumber']}
          form={form}
          getData={handleSubmitFinal}
        />
        {/** end address */}

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.speedonline')}>
            {getFieldDecorator('foreignUserId', {
              rules: [setRuleMin(1), setRuleMax(8)],
              normalize: value => setFormatMarkets(value),
              initialValue: previousForm.foreignUserId ? previousForm.foreignUserId : ''
            })(
              <Input
                jest-id="input"
                disabled={readOnly || isLock}
                placeholder="###"
                autoComplete="off"
                type="string"
                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                minLength={1}
                maxLength={8}
              />
            )}
          </Form.Item>
        </Col>

        {readOnly && type !== 'validate' && (
          <InfoUsersOnReadOnly
            previousForm={previousForm}
            form={form}
            readOnly={readOnly || isLock}
          />
        )}

        {!externalRegister && (
          <>
            {type === 'validate' && (
              <>
                <Col {...layoutInputs}>
                  <Form.Item label={t('usersform.entrydate')}>
                    {getFieldDecorator(
                      'validFrom',
                      configValidFrom(previousForm)
                    )(
                      <DatePicker
                        jest-id="picker"
                        disabled={false}
                        format={dateFormat}
                        placeholder={t('system.select')}
                        disabledDate={date => disabledPastDays(date)}
                        onChange={date => {
                          onStartChange(date);
                        }}
                        onOpenChange={handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col {...layoutInputs}>
                  <Form.Item label={t('usersform.terminationdate')}>
                    {getFieldDecorator(
                      'validTo',
                      configValidTo(previousForm)
                    )(
                      <DatePicker
                        jest-id="date"
                        disabled={false}
                        format={dateFormat}
                        disabledDate={date => disabledEndDate(date)}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                        placeholder={t('system.select')}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            {type !== 'validate' && (
              <>
                <Col {...layoutInputs}>
                  <Form.Item label={t("usersform.entrydate")}>
                    {getFieldDecorator(
                      'validFrom',
                      configValidFrom(previousForm)
                    )(
                      <DatePicker
                        disabled={readOnly || isLock}
                        format={dateFormat}
                        placeholder={t('system.select')}
                        disabledDate={date => disabledPastDays(date)}
                        onChange={date => {
                          onStartChange(date);
                        }}
                        onOpenChange={handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col {...layoutInputs}>
                  <Form.Item label={t("usersform.terminationdate")}>
                    {getFieldDecorator(
                      'validTo',
                      configValidTo(previousForm)
                    )(
                      <DatePicker
                        disabled={readOnly || isLock}
                        format={dateFormat}
                        disabledDate={date => disabledEndDate(date)}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                        placeholder={t('system.select')}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col {...layoutInputs}>
              <SCheckBoxGroup
                jest-id="check"
                form={form}
                inputKey="services"
                isRequired
                disabled={readOnly && type !== 'validate'}
                label={t('catalogs.detail.insurancetype')}
                options={formatDisabledCheckbox()}
                onChange={onChangeServices}
                keyToDisabled="disabled"
                optionsByDefault={!isRegister ? previousForm.services : []}
                style={{
                  width: '100%',
                  padding: '5px',
                  textAlign: 'left'
                }}
              />
            </Col>
          </>
        )}
      </Row>

      {externalRegister && (
        <>
          <Col sm={24} md={20} lg={20} xl={20} style={{ marginLeft: '40px' }}>
            <Form.Item className="text-left">
              {getFieldDecorator('acceptTerms', {
                valuePropName: 'checked',
                initialValue:
                  previousForm && previousForm.acceptTerms ? previousForm.acceptTerms : false,
                rules: [
                  {
                    required: true,
                    transform: value => value || undefined,
                    type: 'boolean',
                    message: `El campo términos y condiciones es obligatorio`
                  }
                ]
              })(
                <Checkbox className="text-center">
                  <span>Acepto</span>
                </Checkbox>
              )}
              <MyButton
                type="link"
                label={t('loginform.termscondition')}
                onClick={handleShowModal}
                jest-id="btn"
              />
            </Form.Item>
          </Col>
          <ModalWithTermsAndConditions handleShowModal={handleShowModal} showModal={showModal} />
        </>
      )}

      {willBeUpdated && !readOnly && (
        <Col md={24}>
          <Col {...layoutInputs}>
            <SSwitch
              isRequired={true}
              label={t('insuranceform.step1.changeuserstatus')}
              inputKey="statusId"
              unCheckedChildren="Inactivo"
              checkedChildren="Activo"
              onChange={value => {
                setIsLock(!value);
                setReasonLockRequired(true);
              }}
              initialValue={
                previousForm && previousForm.statusId && previousForm.statusId === 1 ? true : false
              }
              form={form}
            />
          </Col>

          {isLock && (
            <>
              <Col {...layoutInputs}>
                <SInput
                  form={form}
                  label={t('users.blockingreason')}
                  inputKey="lockReason"
                  isRequired={true}
                  disabled={true}
                  initialValue={
                    previousForm && previousForm.lockReason
                      ? previousForm.lockReason
                      : t('managementLock')
                  }
                  normalize='formatMarkets'
                />
              </Col>
            </>
          )}

          {reasonLockRequired && (
            <Col md={24}>
              <Col {...layoutInputs}>
                <SInput
                  label={t('awarding.comment')}
                  form={form}
                  isRequired={reasonLockRequired}
                  inputKey="comments"
                  inputType="textarea"
                  maxLength={120}
                  minRequired={2}
                  rows={3}
                  normaliza='formatMarkets'
                />
              </Col>
            </Col>
          )}
        </Col>
      )}

      {type === 'validate' && (
        <Col sm={12} md={12} lg={8} xl={8}>
           <MarketsCatalog
                    label={t('table.insurance')}
                    isRequired={true}
                    form={form}
                    inputKey="insuranceCarriers"
                    catalogType="checkbox"
                    withAllOption={true}
                    isModeTagsUsed={true}
                    mode="multiple"
                    cbxHeight={200}
                    />
        </Col>
      )}

      {type !== 'validate' && (
        <FooterWizardActions
          jest-id="footer"
          meta={'Moral Buyer Form - Step 1'}
          onCancel
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
          onNext={{ show: !readOnly && (isRegister || externalRegister), loading: saving, disabled: saving }}
          onSubmit={{ show: !readOnly && !isRegister && !externalRegister, loading: saving, disabled: saving }}
          handleAction={handleFooterActionButtons}
        />
      )}

      {type === 'validate' && (
        <>
          <Modal
            jest-id="modal"
            title={<Title level={4}>{t('insuredforms.addcomments')}</Title>}
            visible={showComments}
            onOk={e => handleRejecting(e)}
            okText={t('system.acept')}
            cancelText={t('system.cancel')}
            onCancel={handleShowComments}
          >
            <TextArea
              onChange={handleChangeComment}
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder={t('insuredforms.step1.describe')}
              value={comments}
            />
          </Modal>
          <div className="steps-action float-right">
            <MyLink type="default" label={t('system.cancel')} to={mainHome} />

            <SButton
              loading={rejecting}
              disabled={rejecting}
              type="primary"
              label={rejecting ? 'Rechazando' : 'Rechazar'}
              onClick={handleShowComments}
            />

            <SButton
              loading={activating}
              label={activating ? 'Activando' : 'Activar'}
              disabled={activating}
              type="primary"
              onClick={e => prepareDataToChangeStatus(e, 1)}
            />
          </div>
        </>
      )}
    </Form>
  );
}

const Step1 = Form.create({ name: 'add-moral-buyer-user' })(Step1Component);

Step1.propTypes = {
  readOnly: PropTypes.bool,
  willBeUpdated: PropTypes.bool,
  type: PropTypes.string
};
Step1.defaultProps = {
  readOnly: false,
  willBeUpdated: false,
  type: 'register'
};

export default Step1;
