import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import SInput from 'components/common/SInput';
import CountriesSelect from 'components/common/catalogs/CountriesSelect';
import { useTranslation } from 'react-i18next';

import StatesSelect from 'components/common/catalogs/StatesSelect';

function AddressInputs(props) {
  const { form, previousForm, readOnly, fieldsNotRequired, otherSInput, isMask } = props;

  const previous = typeof previousForm !== 'undefined' ? previousForm : false;
  const countryId = previous && previous.address ? previous.address.countryId : undefined;
  const { t } = useTranslation();

  const setDefaultCountry = () => {
    if (countryId === '00000000-0000-0000-0000-000000000000') {
      return null;
    }
    return previous && previous.address && previous.address.countryId
      ? previous.address.countryId
      : [];
  };

  return (
    <>
      <Col sm={12} md={12} lg={8} xl={8}>
        <CountriesSelect
          disabled={readOnly}
          form={form}
          initialValue={setDefaultCountry()}
          isRequired={!fieldsNotRequired.includes('countryId')}
        //   onChange={handleSelectCountry}
        />
      </Col>

      <Col sm={12} md={12} lg={8} xl={8}>
        <StatesSelect
          initialValue={previous && previous.address ? previous.address.stateId : []}
          form={form}
          data-testid="states-select"
          disabled={!readOnly ? false : true}
          isRequired={!fieldsNotRequired.includes('stateId')}
          label={t('usersform.state')}
          placeholder={t('system.select')}
        />
      </Col>

      <Col sm={24} md={24} lg={24} xl={24}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.roadName : ''}
          inputKey="roadName"
          isRequired={fieldsNotRequired.includes('roadName') ? false : true}
          label={t('usersform.street')}
          maxLength={1050}
          minRequired={2}
          placeholder=""
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>

      <Col sm={8} md={8} lg={8} xl={8}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.outNumber : ''}
          inputKey="outNumber"
          isRequired={fieldsNotRequired.includes('outNumber') ? false : true}
          label={t('usersform.numext')}
          maxLength={50}
          minRequired={1}
          placeholder="###"
          type="string"
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>

      <Col sm={8} md={8} lg={8} xl={8}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.inNumber : ''}
          inputKey="inNumber"
          isRequired={fieldsNotRequired.includes('inNumber') ? false : true}
          label={t('usersform.numint')}
          maxLength={50}
          minRequired={1}
          placeholder="###"
          type="string"
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>

      <Col sm={8} md={8} lg={8} xl={8}>
        <SInput
          allowedCharactersMask={'[0123456789]'}
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.zipCode : ''}
          inputKey="zipCode"
          inputType={isMask ? 'mask' : ''}
          isRequired={fieldsNotRequired.includes('zipCode') ? false : true}
          label={t('usersform.postalcode')}
          maxLength={5}
          minRequired={5}
        />
      </Col>

      <Col sm={12} md={12} lg={8} xl={8}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.neighborhood : ''}
          inputKey="neighborhood"
          isRequired={fieldsNotRequired.includes('neighborhood') ? false : true}
          label={t('usersform.neighborhood')}
          maxLength={100}
          minRequired={2}
          placeholder=""
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>

      <Col sm={12} md={12} lg={8} xl={8}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.town : ''}
          inputKey="town"
          isRequired={fieldsNotRequired.includes('town') ? false : true}
          label={t('usersform.delegation')}
          maxLength={50}
          minRequired={2}
          placeholder=""
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>

      <Col sm={12} md={12} lg={8} xl={8}>
        <SInput
          autoComplete="off"
          disabled={readOnly}
          form={form}
          initialValue={previous && previous.address ? previous.address.city : ''}
          inputKey="city"
          isRequired={fieldsNotRequired.includes('city') ? false : true}
          label={t('usersform.city')}
          maxLength={50}
          minRequired={2}
          placeholder=""
          {...otherSInput}
          normalize='formatMarkets'
        />
      </Col>
    </>
  );
}

AddressInputs.propTypes = {
  fieldsNotRequired: PropTypes.array,
  form: PropTypes.object.isRequired,
  otherSInput: PropTypes.object,
  readOnly: PropTypes.bool,
  requestCountries: PropTypes.func,
  isMask: PropTypes.bool
};

AddressInputs.defaultProps = {
  fieldsNotRequired: ['inNumber', 'stateId'],
  otherSInput: { normalize: 'capital' },
  readOnly: false,
  requestCountries: null,
  isMask: true
};

export default AddressInputs;
