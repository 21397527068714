import React, { forwardRef } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import InputMask from 'components/common/InputMask';
import { setRuleRequired, setRuleMin } from 'utils/validations';
import { phoneFormat } from 'utils/constants';

/**
 * SInputPhone is an encapsulation of SInputMask to control de input of phones.
 * Formats de data provided as .+521093130193
 */

const SInputPhone = forwardRef((props, ref) => {
  const { inputKey, label, placeholder, form, initialValue, isRequired, mask, ...other } = props;
  const { getFieldDecorator } = form;

  const getPrefixPhoneNumber = phoneNumber => {
    // if (phoneNumber.length === 10) {
    //   return phoneNumber;
    // }
    return phoneNumber.substring(3, phoneNumber.length);
  };

  const cleanPhoneNumber = phoneNumber => {
    let clean = '';
    if (phoneNumber !== '') {
      clean = phoneNumber.replace(/ /g, '');
      clean = clean.replace(/-/g, '');
      if (clean.length === 13) {
        return getPrefixPhoneNumber(clean);
      } else {
        if (clean.length > 10) {
          clean = clean.substring(0, 10);
        }
        return clean;
      }
    }
  };

  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <Form.Item label={label}>
      {getFieldDecorator(inputKey, {
        rules: [setRuleMin(10), ...requiredRule],
        initialValue: cleanPhoneNumber(initialValue)
      })(
        <InputMask
          ref={ref}
          addonBefore={'+52'}
          mask={mask}
          className="border-radius-right"
          autoComplete="off"
          placeholder={placeholder}
          {...other}
        />
      )}
    </Form.Item>
  );
});

SInputPhone.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputKey: PropTypes.string,
  isRequired: PropTypes.bool,
  initialValue: PropTypes.string,
  mask: PropTypes.string
};

SInputPhone.defaultProps = {
  label: 'Teléfono',
  placeholder: '',
  inputKey: 'phone',
  isRequired: false,
  initialValue: '',
  mask: phoneFormat
};

SInputPhone.displayName = 'SInputPhone';

export default SInputPhone;
