import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function MyLink(props) {
  const { label, to, type, className, disabled, objWithCallbacks, onClick } = props;

  const disableLink = {
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    borderColor: '#d9d9d9',
    textShadow: 'none',
    boxShadow: 'none'
  };

  const getClassName = () => {
    switch (type) {
      case 'button':
        return `ant-btn ant-btn-primary ant-btn-round btn-link ${className}`;
      case 'default':
        return `ant-btn ant-btn-default ant-btn-round btn-link ${className}`;
      default:
        return `ant-btn ant-btn-default ant-btn-round btn-link ${className}`;
    }
  };

  const createObjTo = useCallback(() => {
    if (objWithCallbacks) {
      return { ...to, ...objWithCallbacks };
    } else {
      return to;
    }
  }, [objWithCallbacks, to]);

  return (
    <Link style={disabled ? disableLink : {}} className={getClassName()} to={createObjTo()}  onClick={onClick ?? null}>
      {label}
    </Link>
  );
}
MyLink.propTypes = {
  /* Label to be rendered in the Link */
  label: PropTypes.string,
  /* Absolute path to link to*/
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  /* Form of the link */
  type: PropTypes.oneOf(['button', 'default', null]),
  disabled: PropTypes.bool,
  className: PropTypes.string
};

MyLink.defaultProps = {
  label: 'Link',
  to: '/',
  type: 'button',
  className: '',
  disabled: false
};

export default MyLink;
