import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

/**
 * SButton is an encapsulation of Ant Desing Button.
 */

function SButton(props) {
  const { label, shape, children, ...other } = props;
  return (
    <Button shape={shape === 'square' ? null : shape} {...other}>
      {label}
      {children}
    </Button>
  );
}

SButton.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.oneOf(['round', 'circle', 'square']),
  children: PropTypes.element
};

SButton.defaultProps = {
  label: '',
  shape: 'round',
  children: null
};

export default SButton;
