import React from 'react';
import { Form, Col, Input } from 'antd';
import { layoutInputs } from '../../../helpers/helperForms';
import { useTranslation } from 'react-i18next';

function InfoUsersOnReadOnly(props) {
  const { form, previousForm, readOnly, changeStatusEnable } = props;
  const { getFieldDecorator } = form;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {!changeStatusEnable && (
        <Col {...layoutInputs}>
          <Form.Item label={t('table.status')}>
            {getFieldDecorator('status', {
              initialValue: previousForm && previousForm.status ? previousForm.status : ''
            })(
              <Input
                disabled={readOnly}
                maxLength={50}
                minLength={2}
                placeholder=""
                type="string"
              />
            )}
          </Form.Item>
        </Col>
      )}

      {!changeStatusEnable && (
        <Col {...layoutInputs}>
          <Form.Item label={t('users.blockingreason')}>
            {getFieldDecorator('lockReason', {
              initialValue: previousForm && previousForm.lockReason ? previousForm.lockReason : '',
              rules: [{ required: true }]
            })(
              <Input
                disabled={readOnly}
                maxLength={50}
                minLength={2}
                placeholder=""
                type="string"
              />
            )}
          </Form.Item>
        </Col>
      )}
    </React.Fragment>
  );
}

export default InfoUsersOnReadOnly;
