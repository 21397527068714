import React from 'react';
import { Menu, Dropdown, Button, Icon } from 'antd';
import PropTypes from 'prop-types';

function DropDownButton(props) {
  const { ...other } = props;
  const { menu, label, down, onItemClick } = props;

  /* istanbul ignore next */
  const onClick = item => {
    //Injects this params { item, key, keyPath, domEvent }
    if (onItemClick) onItemClick(item);
  };

  const generateMenu = arrayOfMenuItems => {
    return (
      <Menu onClick={onClick}>
        {arrayOfMenuItems.map(menuItem => {
          return (
            <Menu.Item key={menuItem.id} danger>
              {menuItem.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={generateMenu(menu)} placement="bottomLeft" arrow>
      <Button {...other}>
        {label} <Icon type={down ? 'down' : undefined} />
      </Button>
    </Dropdown>
  );
}

DropDownButton.propTypes = {
  menu: PropTypes.array,
  label: PropTypes.string,
  icon: PropTypes.string,
  down: PropTypes.bool,
  onItemClick: PropTypes.func
};

DropDownButton.defaultProps = {
  menu: [{ id: 0, label: 'Option' }],
  label: 'Label',
  icon: 'eye',
  down: true,
  onItemClick: null
};

export default DropDownButton;
