import React from 'react';
import MyModal from '../MyModal';
import TermsAndConditions from '../TermsAndConditions';
import STitle from '../STitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ModalWithTermsAndConditions(props) {
  const { handleShowModal, showModal } = props;
  const { t } = useTranslation();

  return (
    <MyModal
      title={<STitle titleText={t('loginform.termscondition')} className="modal-title" level={3} />}
      footer={null}
      handleShow={handleShowModal}
      show={showModal}
      component={TermsAndConditions}
    />
  );
}

export default ModalWithTermsAndConditions;

ModalWithTermsAndConditions.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

ModalWithTermsAndConditions.defaultProps = {
  showModal: false
};
