import React from 'react';
import { Spin, Icon } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Loader(props) {
  let { size, text, className, isPageLoader } = props;
  const { t } = useTranslation();

  const antIcon = <Icon type="loading" style={{ fontSize: size }} spin />;

  return (
    <div className={isPageLoader ? 'loader-page' : 'loader-internal'}>
      <Spin tip={t(text)} className={className} indicator={antIcon} />
    </div>
  );
}

Loader.propTypes = {
  /* Customize description to display and aditional message down the spin */
  text: PropTypes.string,

  /* Font size of the icon loading it can be a string like 16px or a number */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /* Classname applied to the Spin Element */
  className: PropTypes.string,

  /* when true show a Loader with the class loader page that is intend to cover a full page*/
  isPageLoader: PropTypes.bool
};

Loader.defaultProps = {
  text: 'waitingMessage',
  size: 24,
  className: '',
  isPageLoader: false
};

export default Loader;
