import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Checkbox } from 'antd';
import { setRuleRequired, setRuleMax, setRuleMin, setCapitalLetter, setFormatMarkets } from 'utils/validations';
import { layoutInputs } from 'helpers/helperForms';
import SInput from 'components/common/SInput';
import STitle from 'components/common/STitle';
import FooterWizardActions from 'components/common/FooterWizardActions';
import PropTypes from 'prop-types';
import { mainHome } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { T } from 'antd/lib/upload/utils';
import RegimeSelect from 'components/common/catalogs/RegimeSelect';
import { useSelector } from 'react-redux';
import MarketsCatalog from 'components/common/catalogs/MarketsCatalog';
import { validateAddressProof } from 'utils/helperActions';
import { userStatus } from 'models/enums';

function Step3Component(props) {
  const {
    currentProfile,
    enableInputs,
    externalRegister,
    form,
    formToState,
    isAdmin,
    prev,
    previousForm,
    readOnly,
    type,
    willBeUpdated,
    userId,
    saving,
    disableUpdateOnValidation,
    blockBtn,
    isLock,
    isRegistering,
    isRegister
  } = props;
  let history = useHistory();
  const { getFieldDecorator, validateFields, getFieldsValue } = form;
  let { userIsLock, canUpdateBuyers } = props;
  const { push } = history;
  const { t } = useTranslation();
  const [regimeId, setRegimeId] = useState(null);
  const markets = useSelector(state => state.catalogs.markets);
  const proofAddress = useSelector((state) => state.users.proofAddress);
  const {userData} = useSelector(state => state.wizardForm);
  let previous = typeof previousForm !== 'undefined' ? previousForm : false;
  previous = typeof previous.workInfo !== 'undefined' ? { ...previous.workInfo } : previous;
  userIsLock = typeof userIsLock !== 'undefined' ? userIsLock : false;
  userIsLock = externalRegister ? false : userIsLock;
  canUpdateBuyers = typeof canUpdateBuyers !== 'undefined' ? canUpdateBuyers : false;

  useEffect(() => {
    let mounted = true;
    return () => {
      if(!readOnly && mounted && props.onDismount) {
        mounted = false;
        props.onDismount( formatDataToSave(props.form.getFieldsValue(), false), 2, 'moralBuyer');
      };
    }
  }, [])

  const formatDataToSave = values => {
    let oldValues = { ...values };
    let newValues = {};
    let setMarkets = {};
    let marketsSelected = false;

    if (typeof values.insuranceCarriers !== 'undefined') {
      marketsSelected = markets.filter(m => {
        if (values.insuranceCarriers?.includes(m.id) && m.id != '*****') {
          return m;
        }
        return false;
      });
      setMarkets = marketsSelected;

      if (setMarkets) {
        setMarkets = setMarkets.map(m => {
          return {
            id: m.id,
            name: m.name
          };
        });
      }
      newValues = {
        ...oldValues,
        insuranceCarriers: setMarkets
      };
    } else {
      newValues = {
        ...oldValues
      };
    }
    const dataToSave = { workInfo: { ...newValues }, roleId: currentProfile };
    return dataToSave;
  };

  const saveDataToStore = (values, saveAsLastStep) => {
    const dataToSave = formatDataToSave(values);
    formToState(dataToSave, 2, saveAsLastStep, 'Buyers/BuyerCompany', undefined, userIsLock);
  };

  const handlePreviousButtonOnClick = () => {
    const values = getFieldsValue();
    saveDataToStore(values, false);
    prev();
  };

  const handleSubmitFinal = () => {
    if(validateAddressProof(proofAddress, (isRegister || userData?.companyInfo?.statusId == userStatus.INACTIVE))){
      validateFields(async (err, values) => {
        if (!err) {
          saveDataToStore(values, true);
          if (blockBtn) blockBtn();
        }
      });
    }
  };

  const setToPageCancel = () => {
    let page = '';
    if (!readOnly && willBeUpdated) {
      page = `/users/buyer/detail/${userId}`;
    } else {
      page = mainHome;
    }
    if (readOnly) {
      page = '/users/search/close';
    }
    return page;
  };

  const handleFooterActionButtons = actionId => {
    switch (actionId) {
      case 'prev':
        handlePreviousButtonOnClick();
        break;
      case 'update':
        enableInputs();
        break;
      case 'cancel':
        push(setToPageCancel());
        break;
      case 'submit':
        handleSubmitFinal();
        break;

      default:
        break;
    }
  };

  const createMarketsCheckbox = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      return markets.map(m => {
        return {
          label: m.businessName,
          title: m.businessName,
          value: m.id,
          checked: true,
          defaultChecked: true
        };
      });
    }
  };

  const createDefaultValues = () => {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      let defaultValues = [];
      if (previous && previous.insuranceCarriers) {
        defaultValues = previous.insuranceCarriers.filter(m => m.id);
        defaultValues = defaultValues.map(d => d.id);
        return defaultValues;
      } else {
        defaultValues = markets.map(m => m.id);
      }
      return defaultValues;
    }
  };

  const handleCancelTypeValidate = () => {
    history.push(mainHome);
  };

  const handleRegime = (e) =>{
    setRegimeId(e);
  }

  return (
    <Form noValidate className="text-left">
      <STitle className="wizard-title" level={4} titleText={t('usersform.laborinfo')} />

      <Row gutter={32} className="row-forms">
        {!willBeUpdated && !externalRegister && (
          <Col {...layoutInputs}>
            <SInput
              label={t('usersform.comments')}
              readOnly={readOnly || userIsLock}
              disabled={readOnly}
              form={form}
              inputKey="comments"
              inputType="textarea"
              initialValue={previous && previous.comments ? previous.comments : ''}
              maxLength={120}
              maxRequired={120}
              minRequired={2}
              rows={3}
              normalize='formatMarkets'
            />
          </Col>
        )}

        {!externalRegister && isAdmin && type !== 'validate' && (
          <Col sm={24} md={24} lg={16} xl={16}>
            <MarketsCatalog
                    label={t('table.insurance')}
                    isRequired={true}
                    form={form}
                    inputKey="insuranceCarriers"
                    catalogType="checkbox"
                    withAllOption={true}
                    isModeTagsUsed={true}
                    mode="multiple"
                    cbxHeight={200}
                    disabled={readOnly}
                    optionsByDefault={previous && previous.insuranceCarriers ? previous.insuranceCarriers : []}
                    />
          </Col>
        )}
      </Row>

      {type === 'validate' ? (
        <FooterWizardActions jest-id="footer" handleAction={handleCancelTypeValidate} onCancel />
      ) : (
        <FooterWizardActions
          jest="footer"
          handleAction={handleFooterActionButtons}
          onCancel
          onPrev={{ show: !readOnly && (isRegister || externalRegister) }}
          onSubmit={{ show: !readOnly, loading: saving, disabled: saving }}
          onUpdate={{ show: readOnly && canUpdateBuyers, disabled: disableUpdateOnValidation }}
        />
      )}
    </Form>
  );
}

const Step3 = Form.create({ name: 'add-moral-buyer-user' })(Step3Component);

export default Step3;
Step3.propTypes = {
  externalRegister: PropTypes.bool,
  readOnly: PropTypes.bool,
  willBeUpdated: PropTypes.bool,
  type: PropTypes.string,
  blockBtn: PropTypes.func
};

Step3.defaultProps = {
  externalRegister: false,
  readOnly: false,
  willBeUpdated: false,
  type: 'register',
  blockBtn: undefined
};
