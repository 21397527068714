import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Input, Button, Col, Select, Row, Checkbox, DatePicker, Modal } from 'antd';
import {
  setRuleRequired,
  setRuleMin,
  setRuleMax,
  setRuleEmail,
  setLowerCase,
  setCapitalLetter,
  completePhoneNumber,
  disabledPastDays,
  removeAccent,
  setFormatMarkets
} from 'utils/validations';
import { layoutInputs } from 'helpers/helperForms';
import { setDateNow, configValidFrom, configValidTo } from 'helpers/helperForms';
import { mainHome, dateFormat } from 'utils/constants';
import SInputPhone from 'components/common/SInputPhone';
import SInput from 'components/common/SInput';
import MyButton from 'components/common/MyButton';
import STitle from 'components/common/STitle';
import MyLink from 'components/common/MyLink';
import InfoUsersOnReadOnly from 'components/common/InfoUsersOnReadOnly';
import { searchCases } from 'services/cases';
import SSwitch from 'components/common/SSwitch';
import PropTypes from 'prop-types';
import TermsAndConditions from 'components/common/TermsAndConditions';
import SToggleModalControl from 'components/common/SToggleModalControl';
import CountriesSelect from 'components/common/catalogs/CountriesSelect';
import LanguagesSelect from '../../catalogs/LanguagesSelect';
import TimeZonesSelect from '../../catalogs/TimeZonesSelect';
import { useTranslation } from 'react-i18next'
import { formatDateToISO } from 'utils/cleanUtils';

function Step1Component(props) {
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    currentProfile,
    markets,
    statusUsers,
    form,
    externalRegister,
    insuredExternal,
    isRegistering,
    previousForm,
    activating,
    rejecting,
    readOnly,
    enableInputs,
    willBeUpdated,
    updateForm,
    changeStatusEnable,
    redirectToPage,
    canUpdate,
    type,
    isUpdate
  } = props;

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const [startValue, setStartValue] = useState(null);
  const [comments, setComments] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const { t } = useTranslation();

  const isExternal = externalRegister;
  let previous = typeof updateForm !== 'undefined' ? updateForm : false;
  previous = typeof previousForm !== 'undefined' && previousForm ? previousForm : previous;
  previous =
    previous && typeof previous.personalInfo === 'undefined'
      ? { personalInfo: { ...previous } }
      : previous;

  const [reasonLockRequired, setReasonLockRequired] = useState(false);

  const [isLock, setIsLock] = useState(previous && previous.statusId ? true : false);

  const disabledEndDate = endValue => {
    if (!endValue || !startValue) {
      return false;
    }
    var start = moment(startValue).add(1, 'days');

    return endValue.valueOf() <= start.valueOf();
  };

  const onStartChange = value => {
    setStartValue(value);
  };

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open);
  };

  const handleSubmit = (e, status) => {
    e.preventDefault();
    let updateStatus = null;
    if (typeof status !== 'undefined') {
      updateStatus = status;
    }

    props.form.validateFields((err, values) => {
      if (!err && values.insuranceCarrierName !== '') {
        let contactInfo = {
          onlyCellPhoneNumber: values.cellPhoneNumber,
          cellPhoneNumber: completePhoneNumber(
            values.prefixCellPhoneNumber,
            values.cellPhoneNumber
          ),
          prefixCellPhoneNumber: values.prefixCellPhoneNumber,
          email: values.email
        };

        let personalInfo = {
          givenName: values.givenName,
          surname: values.surname,
          lastname: values.lastname,
          countryId: values.countryId,
          languageId: values.languageId,
          timeZoneId: values.timeZoneId,
          taxId: values.taxId,
          curp: values.curp,
          comments: values.comments,
          validFrom: isExternal ? 
                    setDateNow(false) : 
                    !isUpdate ?  formatDateToISO(moment(values.validFrom).add(-1, 'days')) + 'T00:00:00' : formatDateToISO(values.validFrom) + 'T00:00:00',
          validTo: isExternal ? setDateNow(true) :  formatDateToISO(values.validTo) + 'T00:00:00',
          statusId: isExternal ? -1 : values.statusId
        };

        if (willBeUpdated) {
          personalInfo.statusId = values.statusId ? 1 : 0;
        }

        let marketsSelected = false;
        if (typeof values.insuranceCarrier !== 'undefined') {
          marketsSelected = markets.filter(m => {
            if (values.insuranceCarrier.includes(m.id)) {
              return m;
            }
            return false;
          });
        }

        if (willBeUpdated) {
          marketsSelected = markets.filter(m => m.name === values.insuranceCarrierName);
        }

        let caseInfo = {
          vehicleSerial: values.vehicleSerial,
          accidentSerial: values.accidentSerial,
          caseNumber: values.caseId
        };

        caseInfo = insuredExternal
          ? { ...insuredExternal, accidentSerial: insuredExternal.caseSerial }
          : caseInfo;

        let currentForm = {
          personalInfo,
          contactInfo,
          caseInfo: {
            ...caseInfo,
            roleId: typeof currentProfile !== 'undefined' ? currentProfile : null,
            insuranceCarrierName: values.insuranceCarrierName
          },
          comments: comments,
          userId: previous ? previous.id : null,
          updateStatus: updateStatus,
          insuranceCarrier: marketsSelected ? marketsSelected[0] : null
        };

        if (willBeUpdated) {
          currentForm.statusId = isLock ? 0 : 1;
        }

        let step = 0;
        props.formToState(currentForm, step, true, 'insPerson');
      } else {
        console.error('erorr');
      }
    });
  };

  const handleChangeComment = e => {
    let value = e.target.value;
    value = setFormatMarkets(value);
    setComments(value);
  };

  const handleRejecting = e => {
    setShowComments(false);
    handleSubmit(e, 0);
  };

  // const createCountriesList = () => {
  //   if (typeof countries !== 'undefined' && countries.length > 0) {
  //     return countries.map(p => (
  //       <Option key={p.id} value={p.id}>
  //         {p.name}
  //       </Option>
  //     ));
  //   } else {
  //     return null;
  //   }
  // };

  const handleShowComments = () => {
    let current = showComments;
    setShowComments(!current);
  };

  const createStatusList = () => {
    if (typeof statusUsers !== 'undefined' && statusUsers.length > 1) {
      return statusUsers.map(p => (
        <Option key={p.id} value={p.id}>
          {p.name}
        </Option>
      ));
    } else {
      return null;
    }
  };

  const handleSearch = async () => {
    //getFieldValue('accidentSerial) field1
    //getFieldValue('caseId') field2
    //getFieldValue('vehicleSerial') field3
    const params = {
      caseSerial: getFieldValue('accidentSerial'),
      caseNumber: getFieldValue('caseId'),
      vehicleSerial: getFieldValue('vehicleSerial')
    };

    try {
      const response = await searchCases(params);
      if (typeof response !== 'undefined' && response.status === 200) {
        setFieldsValue({
          insuranceCarrierName: response.data.data.insuranceName
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setInitialNameInsuranceCarrier = () => {
    let initialInsuranceCarrier = '';

    switch (type) {
      case 'validate':
        initialInsuranceCarrier =
          previous && previous.insuranceCarrier ? previous.insuranceCarrier.name : '';
        break;
      case 'update':
        initialInsuranceCarrier =
          previous && previous.insuranceCarrier ? previous.insuranceCarrier.name : false;
        if (!initialInsuranceCarrier) {
          initialInsuranceCarrier = previous && previous.caseInfo.insuranceCarrierName;
        }
        break;
      case 'detail':
        initialInsuranceCarrier =
          previous && previous.insuranceCarrier ? previous.insuranceCarrier.name : '';
        break;
      case 'autoRegister':
        initialInsuranceCarrier = props.insuranceCarrier
          ? props.insuranceCarrier.insuranceName
          : '';
        break;
      case 'register':
        break;
      default:
        break;
    }

    return initialInsuranceCarrier;
  };

  useEffect(() => {
    setIsLock(previous && previous.statusId === 0);
  }, [previous]);

  const enableInputsOnValidate = () => {
    let enable = false;

    if (readOnly && !changeStatusEnable) {
      enable = true;
    }
    if (isLock) {
      enable = true;
    }

    if (type === 'validate') {
      enable = false;
    }
    if (isExternal) {
      enable = false;
    }

    return enable;
  };

  const disabledInputCaseInfo = () => {
    let disabled = false;
    switch (type) {
      case 'validate':
        disabled = true;
        break;
      case 'register':
        break;
      default:
        break;
    }
    return disabled;
  };

  const disabledOnValidation = () => {
    if (previous && previous.personalInfo.statusId === -1) {
      return true;
    } else {
      return canUpdate;
    }
  };

  return (
    <Form noValidate className="text-left" onSubmit={handleSubmit}>
      <Row gutter={24} className="row-forms">
        <STitle className="wizard-title" level={4} titleText={t('usersform.personalinfo')} />

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.name')}>
            {getFieldDecorator('givenName', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Nombre(s)')],
              normalize: value => setFormatMarkets(value),
              initialValue:
                previous && previous.personalInfo.givenName ? previous.personalInfo.givenName : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.firstlastname')}>
            {getFieldDecorator('surname', {
              rules: [setRuleMin(2), setRuleMax(50), setRuleRequired('Primer apellido')],
              normalize: value => setFormatMarkets(value),
              initialValue:
                previous && previous.personalInfo.surname ? previous.personalInfo.surname : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.secondlastname')}>
            {getFieldDecorator('lastname', {
              rules: [setRuleMin(2), setRuleMax(50)],
              normalize: value => setFormatMarkets(value),
              initialValue:
                previous && previous.personalInfo.lastname ? previous.personalInfo.lastname : ''
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder=""
                autoComplete="off"
                type="string"
                minLength={2}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...layoutInputs}>
          <CountriesSelect
            disabled={readOnly || isLock}
            form={form}
            initialValue={
              previous && previous.personalInfo.countryId ? previous.personalInfo.countryId : null
            }
            isRequired={true}
          />
        </Col>

        <Col {...layoutInputs}>
          <LanguagesSelect
            form={form}
            disabled={readOnly || isLock}
            initialValue={
              previous && previous.personalInfo.languageId ? previous.personalInfo.languageId : null
            }
            isRequired={true}
          />
        </Col>

        <Col {...layoutInputs}>
          <TimeZonesSelect
            form={form}
            disabled={readOnly || isLock}
            initialValue={
              previous && previous.personalInfo.timeZoneId ? previous.personalInfo.timeZoneId : null
            }
            isRequired={true}
          />
        </Col>

        {!isExternal && (
          <React.Fragment>
            <Col {...layoutInputs}>
              <Form.Item label={t('usersform.entrydate')}>
                {getFieldDecorator(
                  'validFrom',
                  configValidFrom(previous ? previous.personalInfo : false)
                )(
                  <DatePicker
                    format={dateFormat}
                    placeholder={t('system.select')}
                    disabledDate={date => disabledPastDays(date)}
                    onChange={date => {
                      onStartChange(date);
                    }}
                    onOpenChange={handleStartOpenChange}
                    disabled={enableInputsOnValidate()}
                  />
                )}
              </Form.Item>
            </Col>

            <Col {...layoutInputs}>
              <Form.Item label={t('usersform.terminationdate')}>
                {getFieldDecorator(
                  'validTo',
                  configValidTo(previous ? previous.personalInfo : false)
                )(
                  <DatePicker
                    format={dateFormat}
                    disabledDate={date => disabledEndDate(date)}
                    open={endOpen}
                    disabled={enableInputsOnValidate()}
                    onOpenChange={handleEndOpenChange}
                    placeholder={t('system.select')}
                  />
                )}
              </Form.Item>
            </Col>

            {typeof statusUsers !== 'undefined' &&
              previous &&
              !readOnly &&
              !willBeUpdated &&
              isLock && (
                <Col sm={12} md={12} lg={6} xl={6}>
                  <Form.Item label={t('publication.status')} className="text-left">
                    {getFieldDecorator('statusId', {
                      rules: [{ required: true, message: 'Campo obligatorio' }],
                      initialValue: previous && previous.statusId ? previous.statusId : '',
                      placeholder: 'Seleccionar'
                    })(<Select>{createStatusList()}</Select>)}
                  </Form.Item>
                </Col>
              )}
          </React.Fragment>
        )}

        {readOnly && (
          <InfoUsersOnReadOnly
            changeStatusEnable={changeStatusEnable}
            form={form}
            previousForm={previousForm}
            readOnly={readOnly || isLock}
            detailUser={isExternal}
          />
        )}

        <Col {...layoutInputs}>
          <SInputPhone
            form={form}
            label={t('usersform.cellnumber')}
            inputKey="cellPhoneNumber"
            initialValue={
              previous && previous.contactInfo && previous.contactInfo.cellPhoneNumber
                ? previous.contactInfo.cellPhoneNumber
                : ''
            }
            disabled={type !== 'validate' ? enableInputsOnValidate() : true}
            className="border-radius-right"
            autoComplete="off"
          />
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.email')}>
            {getFieldDecorator('email', {
              rules: [
                setRuleEmail('Correo electrónico'),
                setRuleRequired('Correo electrónico'),
                setRuleMax(80)
              ],
              initialValue:
                previous && previous.contactInfo && previous.contactInfo.email
                  ? previous.contactInfo.email
                  : '',
              normalize: value => removeAccent(value)
            })(
              <Input
                disabled={readOnly || isLock}
                placeholder={t('adminforms.step2.user')}
                autoComplete="none"
                type="email"
                maxLength={80}
              />
            )}
          </Form.Item>
        </Col>

        {!isExternal && !willBeUpdated && type !== 'validate' && (
          <Col {...layoutInputs}>
            <SInput
              label={t('usersform.comments')}
              disabled={enableInputsOnValidate()}
              form={form}
              inputKey="comments"
              inputType="textarea"
              initialValue={previous && previous.comments ? previous.comments : ''}
              maxLength={120}
              minRequired={2}
              rows={3}
              normalize='formatMarkets'
            />
          </Col>
        )}

        {willBeUpdated && !readOnly && (
          <Col lg={24} md={24}>
            <>
              <Col {...layoutInputs}>
                <SSwitch
                  isRequired={true}
                  label={t('insuranceform.step1.changeuserstatus')}
                  inputKey="statusId"
                  unCheckedChildren="Inactivo"
                  checkedChildren="Activo"
                  onChange={value => {
                    setIsLock(!value);
                    setReasonLockRequired(true);
                    setFieldsValue({
                      lockReason: 'Bloqueo Administrativo'
                    });
                  }}
                  initialValue={
                    previousForm && previousForm.statusId && previousForm.statusId === 1
                      ? true
                      : false
                  }
                  form={form}
                />
              </Col>

              {isLock && (
                <>
                  <Col {...layoutInputs}>
                    <Form.Item label={t('users.blockingreason')}>
                      {getFieldDecorator('lockReason', {
                        initialValue:
                          previous && previous.lockReason ? previous.lockReason : t('managementLock'),
                        rules: [{ required: true },],
                        normalize: value => setFormatMarkets(value),
                      })(<Input disabled={true} type="string" />)}
                    </Form.Item>
                  </Col>
                </>
              )}

              {reasonLockRequired && (
                <Col {...layoutInputs}>
                  <SInput
                    label={t('awarding.comment')}
                    disabled={readOnly}
                    isRequired={reasonLockRequired}
                    form={form}
                    inputKey="comments"
                    inputType="textarea"
                    maxLength={120}
                    minRequired={2}
                    rows={3}
                    normalize='formatMarkets'
                  />
                </Col>
              )}
            </>
          </Col>
        )}

        <Col span={24}>
          <STitle className="wizard-title" level={4} titleText={t('usersform.caseinformation')} />
        </Col>

        {willBeUpdated && !insuredExternal && (
          <>
            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.casenumber')}>
                {getFieldDecorator('accidentSerial', {
                  initialValue:
                    previous && previous.caseInfo.accidentSerial
                      ? previous.caseInfo.accidentSerial
                      : '',
                  rules: [setRuleRequired('Número de caso'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={readOnly || isLock}
                    minLength={2}
                    maxLength={13}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.incident')}>
                {getFieldDecorator('caseId', {
                  initialValue:
                    previous && previous.caseInfo.caseNumber ? previous.caseInfo.caseNumber : '',
                  rules: [setRuleRequired('Número de siniestro'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={readOnly || isLock}
                    minLength={2}
                    maxLength={30}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.serial')}>
                {getFieldDecorator('vehicleSerial', {
                  initialValue:
                    previous && previous.caseInfo.vehicleSerial
                      ? previous.caseInfo.vehicleSerial
                      : '',
                  rules: [setRuleRequired('Número de Serie/VIN del Vehículo'), setRuleMax(17)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={readOnly || isLock}
                    minLength={1}
                    maxLength={17}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>
            {!readOnly && (
              <Col sm={12} md={8} lg={3} xl={3}>
                <br />
                <br />
                <MyButton
                  disabled={readOnly}
                  label={t('system.search')}
                  type="primary"
                  onClick={handleSearch}
                />
              </Col>
            )}
          </>
        )}
        {/**cuándo será actualizado por una aseguradora master o admin*/}
        {!willBeUpdated && !insuredExternal && (
          <>
            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.casenumber')}>
                {getFieldDecorator('accidentSerial', {
                  initialValue:
                    previous && previous.caseInfo ? previous.caseInfo.accidentSerial : '',
                  rules: [setRuleRequired('Número de caso'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    minLength={2}
                    maxLength={13}
                    disabled={disabledInputCaseInfo()}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.incident')}>
                {getFieldDecorator('caseId', {
                  initialValue: previous && previous.caseInfo ? previous.caseInfo.caseNumber : '',
                  rules: [setRuleRequired('Número de siniestro'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={disabledInputCaseInfo()}
                    minLength={2}
                    maxLength={30}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.serial')}>
                {getFieldDecorator('vehicleSerial', {
                  initialValue:
                    previous && previous.caseInfo ? previous.caseInfo.vehicleSerial : '',
                  rules: [setRuleRequired('Número de Serie/VIN del Vehículo'), setRuleMax(17)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    minLength={1}
                    disabled={disabledInputCaseInfo()}
                    maxLength={17}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            {!readOnly && !externalRegister && (
              <Col sm={12} md={8} lg={3} xl={3}>
                <br />
                <br />
                <MyButton
                  disabled={readOnly}
                  label={t('system.search')}
                  type="primary"
                  onClick={handleSearch}
                />
              </Col>
            )}
          </>
        )}
        {/**registro externo por un asegurado */}
        {insuredExternal && (
          <>
            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.casenumber')}>
                {getFieldDecorator('accidentSerial', {
                  initialValue: insuredExternal.caseSerial,
                  rules: previous ? [] : [setRuleRequired('Número de caso'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={true}
                    minLength={2}
                    maxLength={13}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.incident')}>
                {getFieldDecorator('caseId', {
                  initialValue: insuredExternal.caseNumber,
                  rules: [setRuleRequired('Número de siniestro'), setRuleMin(2)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={true}
                    minLength={2}
                    maxLength={30}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={12} md={8} lg={7} xl={7}>
              <Form.Item label={t('usersform.serial')}>
                {getFieldDecorator('vehicleSerial', {
                  initialValue: insuredExternal.vehicleSerial,
                  rules: [setRuleRequired('Número de Serie/VIN del Vehículo'), setRuleMax(17)],
                  normalize: value => setFormatMarkets(value),
                })(
                  <Input
                    disabled={true}
                    minLength={1}
                    maxLength={17}
                    placeholder="###"
                    autoComplete="off"
                    type="string"
                  />
                )}
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <STitle className="wizard-title" level={4} titleText={t('usersform.insuranceinfo')} />
        </Col>

        <Col {...layoutInputs}>
          <Form.Item label={t('usersform.insurance')}>
            {getFieldDecorator('insuranceCarrierName', {
              initialValue: setInitialNameInsuranceCarrier(),
              rules: [{ required: true, message: 'El campo Aseguradora es obligatorio' }]
            })(<Input disabled={true} autoComplete="off" type="string" />)}
          </Form.Item>
        </Col>

        {isExternal && !previous && (
          <Col sm={24} md={20} lg={20} xl={20} style={{ marginLeft: '40px' }}>
            <Form.Item className="text-left">
              {getFieldDecorator('acceptTerms', {
                valuePropName: 'checked',
                initialValue: false,
                rules: [
                  {
                    required: true,
                    transform: value => value || undefined,
                    type: 'boolean',
                    message: `El campo términos y condiciones es obligatorio`
                  }
                ]
              })(
                <Checkbox className="text-center">
                  Acepto <Button type="link" className="no-padding" />
                </Checkbox>
              )}
              <SToggleModalControl type="link" labelButton={t('loginform.termscondition')} iconButton="">
                <Modal
                  footer={null}
                  title={
                    <STitle titleText={t('loginform.termscondition')} className="modal-title" level={3} />
                  }
                  width={520}
                  destroyOnClose
                  maskClosable={false}
                >
                  <TermsAndConditions />
                </Modal>
              </SToggleModalControl>
            </Form.Item>
          </Col>
        )}
      </Row>
      <div className="steps-action float-right">
        {type !== 'update' && type !== 'detail' && (
          <MyLink type="default" to={mainHome} label={t('system.cancel')} />
        )}

        {type === 'detail' && <MyLink type="default" to="/users/search/close" label={t('system.cancel')} />}

        {type === 'update' && (
          <MyButton onClick={() => redirectToPage()} type="default" label={t('system.cancel')} />
        )}

        {/** hay 5 escenarios para estos botones  */}

        {readOnly && willBeUpdated && (
          <MyButton
            disabled={disabledOnValidation()}
            type="primary"
            onClick={() => enableInputs()}
            label={t('system.update')}
          />
        )}

        {!previous && !readOnly && (
          <MyButton
            label={isRegistering ? 'Espere' : t('system.acept')}
            loading={isRegistering}
            type="primary"
            onClick={handleSubmit}
          />
        )}

        {previous && !changeStatusEnable && (
          <React.Fragment>
            {!willBeUpdated && !isExternal && (
              <>
                <Modal
                  title={<STitle level={4} titleText={t('insuredforms.addcomments')} />}
                  visible={showComments}
                  onOk={e => handleRejecting(e)}
                  okText={t('system.acept')}
                  cancelText={t('system.cancel')}
                  onCancel={handleShowComments}
                >
                  <TextArea
                    onChange={handleChangeComment}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    placeholder={t('insuredforms.step1.describe')}
                    value={comments}
                  />
                </Modal>

                {type !== 'register' && (
                  <>
                    <MyButton
                      loading={rejecting}
                      disabled={rejecting}
                      type="primary"
                      label={rejecting ? 'Rechazando' : 'Rechazar'}
                      onClick={handleShowComments}
                    />

                    <MyButton
                      loading={activating}
                      label={activating ? 'Activando' : 'Activar'}
                      disabled={activating}
                      type="primary"
                      onClick={e => handleSubmit(e, 1)}
                    />
                  </>
                )}
              </>
            )}
            {!readOnly && (
              <MyButton
                loading={isRegistering}
                label={isRegistering ? 'Espere...' : t('system.acept')}
                disabled={isRegistering}
                type="primary"
                onClick={e => handleSubmit(e, 1)}
              />
            )}
          </React.Fragment>
        )}

        {readOnly && changeStatusEnable && (
          <React.Fragment>
            {!willBeUpdated && !isExternal && (
              <>
                <Modal
                  title={<STitle level={4} titleText={t('insuredforms.addcomments')} />}
                  visible={showComments}
                  onOk={e => handleRejecting(e)}
                  okText={t('system.acept')}
                  cancelText={t('system.cancel')}
                  onCancel={handleShowComments}
                >
                  <TextArea
                    onChange={handleChangeComment}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    placeholder={t('insuredforms.step1.describe')}
                    value={comments}
                  />
                </Modal>

                <MyButton
                  loading={rejecting}
                  disabled={rejecting}
                  type="primary"
                  label={rejecting ? 'Rechazando' : 'Rechazar'}
                  onClick={handleShowComments}
                />

                <MyButton
                  loading={activating}
                  label={activating ? 'Activando' : 'Activar'}
                  disabled={activating}
                  type="primary"
                  onClick={e => handleSubmit(e, 1)}
                />
              </>
            )}
          </React.Fragment>
        )}
      </div>
    </Form>
  );
}

const Step1 = Form.create({ name: 'add-insured-user' })(Step1Component);

Step1.propTypes = {
  readOnly: PropTypes.bool,
  willBeUpdated: PropTypes.bool,
  changeStatusEnable: PropTypes.bool,
  externalRegister: PropTypes.bool,
  insuredExternal: PropTypes.any,
  type: PropTypes.string
};
Step1.defaultProps = {
  readOnly: false,
  willBeUpdated: false,
  changeStatusEnable: false,
  externalRegister: false,
  insuredExternal: false,
  type: 'register'
};

export default Step1;
