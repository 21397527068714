import { Input } from 'antd';
import React, { forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const InputMask = forwardRef((props, ref) => {
  const { disabled } = props;
  return (
    <ReactInputMask maskChar={0} {...props}>
      {inputProps => <Input {...inputProps} ref={ref} disabled={disabled} />}
    </ReactInputMask>
  );
});

InputMask.propTypes = {
  disabled: PropTypes.bool
};

InputMask.defaultProps = {
  disabled: false
};

InputMask.displayName = 'InputMask';

export default InputMask;
