import React from 'react';
import PropTypes from 'prop-types';
import SSelect from 'components/common/SSelect';
import { useTranslation } from 'react-i18next';

function SDocumentAddressTypeID(props) {
  const { form, initialValue, addressID, isMoral, ...other } = props;
  const { t } = useTranslation();

  return (
    <SSelect
      form={form}
      initialValue={initialValue}
      inputKey={isMoral ? 'addressDocumentTypeId' : 'documentTypeId'}
      isLoading={addressID?.isLoading}
      label={t('usersform.addressproof')}
      optionKey="id"
      isRequired={true}
      options={addressID?.data}
      placeholder={t('system.select')}
      valueKey="id"
      {...other}
    />
  );
}

SDocumentAddressTypeID.propTypes = {
  initialValue: PropTypes.any,
  addressID: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestAdvertStatus: PropTypes.func,
  isMoral: PropTypes.bool
};

SDocumentAddressTypeID.defaultProps = {
  initialValue: undefined,
  addressID: { data: [], isLoading: false },
  requestAdvertStatus: null,
  isMoral: false
};

export default SDocumentAddressTypeID;
