import React, { useState, useRef } from 'react';
import SkeletonImage from 'components/common/SkeletonImage';
import { buildURLImage } from 'utils';
import PropTypes from 'prop-types';
import imageFailed from 'assets/img/states/imageFailed.svg';
import { getItemFromLocal } from 'utils';
import './style.css';
function SImage(props) {
  const imageRef = useRef(false);
  const { alt, fileId, width, height, title, onError, onLoad, path, cursor, ...rest } = props;
  const tokenPic = getItemFromLocal('tokenPic');
  const [state, setState] = useState({ imageComplete: false, imageError: '' });

  const style = {
    borderradius: '3px',
    maxwidth: '260px',
    maxheight: '190px',
    mineight: '190px',
    cursor
  };

  const handleImageLoaded = () => {
    setTimeout(() => {
      setState({ imageComplete: true, imageError: '' });
    }, 800);
    /* istanbul ignore else */
    if (typeof onLoad !== 'boolean' && onLoad) {
      onLoad();
    }
  };

  const handleImageError = () => {
    setState({ imageError: 'Failed to load', imageComplete: false });
    /* istanbul ignore else */
    if (typeof onError !== 'boolean' && onError) {
      onError();
    }
  };

  const generateURL = () => {
    return buildURLImage(tokenPic, fileId, path);
  };

  const setStyleToImg = () => {
    return state.imageError !== '' ? { display: 'none' } : { ...style };
  };

  return (
    <div className="simage-container">
      {!state.imageComplete && state.imageError === '' && (
        <div className="skeleton-container" style={{ width: '100%', ...style, zindex: 10 }}>
          <SkeletonImage loading={true} numberOfSkeletons={1} />
        </div>
      )}

      <img
        id={fileId}
        alt={alt}
        data-testid={!state.imageComplete ? 'image-initial' : 'image-complete'}
        className={state.imageComplete ? 'image-complete ' : 'hide'}
        height={height}
        onError={handleImageError}
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={generateURL()}
        title={title}
        width={width}
        {...rest}
        style={setStyleToImg()}
      />

      {state.imageError !== '' && !state.imageComplete && (
        <img data-testid="image-failed" alt="not-loaded" src={imageFailed} {...style} />
      )}
    </div>
  );
}

export default SImage;
SImage.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onError: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onLoad: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

SImage.defaultProps = {
  title: '',
  alt: '',
  onError: false,
  onLoad: false,
  cursor: 'auto'
};
