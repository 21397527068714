import React, { forwardRef } from 'react';
import { Form, Input, Icon } from 'antd';
import PropTypes from 'prop-types';
import {
  setCapitalLetter,
  setFormatMarkets,
  setLowerCase,
  setRuleMin,
  setRuleRequired,
  setUpperCase
} from 'utils/validations';
import InputMask from 'components/common/InputMask';
const { TextArea } = Input;

const SInput = forwardRef((props, ref) => {
  const {
    allowedCharactersMask,
    form,
    initialValue,
    inputKey,
    inputType,
    isRequired,
    label,
    maxLength,
    minRequired,
    normalize,
    customLabel,
    validator,
    ...other
  } = props;

  const { getFieldDecorator } = form;
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];
  const minRule = minRequired > 0 ? [setRuleMin(minRequired)] : [];
  const resultantLabel = customLabel ? customLabel : label;

  let normalizeFunc = null;
  switch (normalize) {
    case 'upper':
      normalizeFunc = value => setUpperCase(value);
      break;
    case 'lower':
      normalizeFunc = value => setLowerCase(value);
      break;
    case 'capital':
      normalizeFunc = value => setCapitalLetter(value);
      break;
    case 'formatMarkets':
      normalizeFunc = value => setFormatMarkets(value);
      break;
    case 'trim':
      normalizeFunc = value => setFormatMarkets(value?.trim());
      break;
    default:
      break;
  }

  const getInputType = () => {
    switch (inputType) {
      case 'string':
        return (
          <Input
            //title={typeof initialValue === 'function' ? initialValue() : initialValue}
            data-testid="alexis"
            ref={ref}
            autoComplete="off"
            maxLength={maxLength}
            {...other}
          />
        );

      case 'textarea':
        return (
          <TextArea
            data-testid="alexis"
            ref={ref}
            autoComplete="off"
            maxLength={maxLength}
            {...other}
          />
        );

      case 'password':
        return (
          <Input.Password
            //title={typeof initialValue === 'function' ? initialValue() : initialValue}
            data-testid="password"
            ref={ref}
            autoComplete="off"
            maxLength={maxLength}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.35)' }} />}
            {...other}
          />
        );

      case 'mask':
        return (
          <InputMask
            data-testid="alexis"
            ref={ref}
            formatChars={{
              '*': allowedCharactersMask
            }}
            mask={'*'.repeat(maxLength ? maxLength : 0)}
            className="border-radius-right"
            autoComplete="off"
            {...other}
          />
        );
      default:
        return <Input data-testid="alexis" ref={ref} autoComplete="off" {...other} />;
    }
  };
  return (
    <Form.Item label={resultantLabel} {...other}>
      {getFieldDecorator(inputKey, {
        initialValue: typeof initialValue === 'function' ? initialValue() : initialValue,
        normalize: normalizeFunc,
        rules: other.disabled ? [...requiredRule] : [...requiredRule, ...minRule, validator]
      })(getInputType())}
    </Form.Item>
  );
});

SInput.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  inputKey: PropTypes.string,
  inputType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minRequired: PropTypes.number,
  normalize: PropTypes.string,
  readOnly: PropTypes.bool,
  allowedCharactersMask: PropTypes.string,
  customLabel: PropTypes.node,
  validator: PropTypes.object
};

SInput.defaultProps = {
  validator: {},
  customLabel: null,
  initialValue: undefined,
  inputKey: 'genericInput',
  inputType: 'string',
  isRequired: false,
  label: 'Label',
  maxLength: null,
  minRequired: 2,
  normalize: null,
  readOnly: false,
  allowedCharactersMask: '[abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ0123456789 .,-_/]'
};

SInput.displayName = 'SInput';

export default SInput;
